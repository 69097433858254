import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function CardCustomer() {

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));
  
    const navigate = useNavigate();
  
    const Redirectif = () => {
      useEffect(() => {
        if (usernameglobal == null) {
          navigate('/LoginPage');
        }
      }, [usernameglobal, navigate]);
    }
  
    try {
      if (usertimestampglobal != null) {
        const parsedTimestamp = parseInt(usertimestampglobal, 10);
        const currentTime = new Date().getTime();
        const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds
  
        if (parsedTimestamp < twelveHoursAgo) {
          localStorage.removeItem('usertimestamp');
          localStorage.removeItem('username');
          Redirectif();
        }
      }
    }
    catch {
      
    }
  
    if(usernameglobal == null){
      Redirectif();
    }
  
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  
    useEffect(() => {
      // Fetch data from the specified URL
      axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)
  
          .then((response) => {
            setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
          })
          .catch((error) => console.error('Error fetching data:', error));
    }, [usernameglobal]);

    function navigatetologin() {
      localStorage.removeItem('usertimestamp');
      localStorage.removeItem('username');
      navigate('/loginpage');
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
    
    }

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const [formData, setFormData] = useState({
      centerid: '',
    });

    return(
      
      <div className="container" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Customer Card</h1>
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">Home</a>
            <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
                </li>
            </ul>
            <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
          </div>
        </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        <div className="col-md-6 offset-md-3">
          <form onSubmit={handleSubmit} method="POST" id="form1">
                        
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="id-customerno" className="form-label">Customer No </label>
                <input type="text" className="form-control" name="customerno" id="id-customerno" />
                {errors.customerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.customerno}</span>}
              </div>
  
              <div className="col-md-8">
                <label htmlFor="id-customername" className="form-label">Customer name </label>
                <input type="text" className="form-control" name="customername" id="id-customername" value={formData.customername} onChange={handleInputChange} />
                {errors.customername && <span style={{color: 'red', fontSize: '10px'}}>{errors.customername}</span>}
              </div>  
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="id-lineno" className="form-label">Line No </label>
                <input type="text" className="form-control" name="lineno" id="id-lineno" />
                {errors.lineno && <span style={{color: 'red', fontSize: '10px'}}>{errors.lineno}</span>}
              </div>
  
              <div className="col-md-6">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange} />
                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="id-fixsaletype" className="form-label">Sales subscription</label>
                <select
                  className="form-select"
                  id="id-saletype"
                  name="saletype" // <-- should match `formData.uom1` property
                  value={formData.saletype}
                  onChange={handleInputChange}
                >
                  <option>s</option>
                  <option>ss</option>
                </select>
                
              </div>
            </div>
            
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="id-cowratibqty" className="form-label">Cow Fix Qty </label>
                <input type="text" className="form-control" name="cowratibqty" id="id-cowratibqty" placeholder='0.00'/>
                {errors.cowratibqty && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowratibqty}</span>}
              </div>
  
              <div className="col-md-6">
                <label htmlFor="id-buffratibqty" className="form-label"> Buff Fix Qty </label>
                <input type="text" className="form-control" name="buffratibqty" id="id-buffratibqty" value={formData.buffratibqty} onChange={handleInputChange} placeholder='0.00'/>
                {errors.buffratibqty && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffratibqty}</span>}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="id-cowraters" className="form-label">Cow Rate Rs </label>
                <input type="text" className="form-control" name="cowraters" id="id-cowraters" placeholder='0.00'/>
                {errors.cowraters && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowraters}</span>}
              </div>
  
              <div className="col-md-6">
                <label htmlFor="id-buffraters" className="form-label"> Buff Rate Rs </label>
                <input type="text" className="form-control" name="buffraters" id="id-buffraters" value={formData.buffraters} onChange={handleInputChange} placeholder='0.00'/>
                {errors.buffraters && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffraters}</span>}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="id-depositrs" className="form-label">Deposit Rs </label>
                <input type="text" className="form-control" name="depositrs" id="id-depositrs" placeholder='0.00'/>
                {errors.depositrs && <span style={{color: 'red', fontSize: '10px'}}>{errors.depositrs}</span>}
              </div>
  
              <div className="col-md-6">
                <label htmlFor="id-balancers" className="form-label"> Balance Rs </label>
                <input type="text" className="form-control" name="balancers" id="id-balancers" value={formData.balancers} onChange={handleInputChange} readOnly/>
                {errors.balancers && <span style={{color: 'red', fontSize: '10px'}}>{errors.balancers}</span>}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-5">
                <label htmlFor="id-fromdate" className="form-label">From Date </label>
                <input type="date" className="form-control" name="fromdate" id="id-fromdate" />
                {errors.fromdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.fromdate}</span>}
              </div>
              
              <div className="col-md-2 text-center">
                {/* <label className="form-label">To</label> */}
              </div>
              
              <div className="col-md-5">
                <label htmlFor="id-todate" className="form-label"> To Date </label>
                <input type="date" className="form-control" name="todate" id="id-todate" value={formData.todate} onChange={handleInputChange} />
                {errors.todate && <span style={{color: 'red', fontSize: '10px'}}>{errors.todate}</span>}
              </div>              
            </div>

            <div className="row mb-3 justify-content-end">
              <div className="col-md-6 d-flex">
                <button type="submit" className="btn btn-primary w-100">View</button>
              </div>

              <div className="col-md-6 d-flex">
                <button type="submit" className="btn btn-primary w-100">Generate</button>
              </div>
            </div>
            
          </form>
        </div>
      </div>
      
      <div className="row mt-4">
        <div className="col-md-12 table-responsive-xl">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Cow Qty</th>
                <th>Cow Rate</th>
                <th>Buff Qty</th>
                <th>Buff Rate</th>
                <th>Debit amt</th>
                <th>Credit amt</th>
                <th>Other Prods</th>
              </tr>
            </thead>
            <tbody>
              
              <tr>
                <td>07/02/2024</td>
                <td>2.5</td>
                <td>50.00</td>
                <td>3.5</td>
                <td>60.00</td>
                <td>1000</td>
                <td>1525</td>
                <td>                       
                  <button className="btn btn-primary btn-sm ml-1" style={{width: '90px', fontSize: '15px'}}>
                  Edit
                  </button>
                </td>
              </tr>
            
            </tbody>
          </table>
          
        </div>
      </div>

    </div>

    );

}

export default CardCustomer;