import React, { useEffect, useState } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';

function CustomerMaster() {
  
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

  const navigate = useNavigate();
  // const usernameglobal='';
  
  const Redirectif = () => {
    useEffect(() => {
      if (usernameglobal == null) {
        navigate('/LoginPage');
      }
    }, [usernameglobal, navigate]);
  } 

  try {
    if (usertimestampglobal != null) {
      const parsedTimestamp = parseInt(usertimestampglobal, 10);
      const currentTime = new Date().getTime();
      const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

      if (parsedTimestamp < twelveHoursAgo) {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        Redirectif();
      }
    }
  }
  catch {
    
  }
  
  if(usernameglobal == null){
    Redirectif();
    // return null; 
  }
  
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const MySwal = withReactContent(Swal);
  const [errors, seterrors] = useState({});
  const [CustomerMasterData, setCustomerMasterData] = useState([]);  
  const [defaultcenter, setdefaultcenter] = useState();
  const [CenterMasterData, setCenterMasterData] = useState([]);
  const [RouteMasterData, setRouteMasterData] = useState([]);
  var cowratib_qty2 = 0.00;
  var buffratib_qty2 = 0.00;


  useEffect(() => {
    // Fetch data from the specified URL
    axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)

        .then((response) => {
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        })
        .catch((error) => console.error('Error fetching data:', error));
  }, [usernameglobal]);

  const [formData, setFormData] = useState({
    centerid: '',
    customer_mp_id: '',
    customer_centerwiseid: '',
    customer_name: '',
    customer_place: '',
    customer_mobile: '',
    whatsapp_no: '',
    routename: '',
    customer_status: '',
    customer_type: '',
    cowratib_qty: '',
    cowratib_qty2: '',
    cowratib_rate: '',
    buffratib_qty: '',
    buffratib_qty2: '',   
    buffratib_rate: '',
    servicecharges: '',
    opbal: '',
    clbal: '',
    deposit_amt: '',
    deposit_receiptno: '',
    deposit_date: '',
    operationtype: 'Add Customer',
    customer_mp_id_hidden: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if(name == "routename"){
      setdefaultroutename(value);
      CustomerMasterDataUpdateonCenteridchange(defaultcenter, value);
    }
    
    if(name == "centerid") {
      setdefaultcenter(value);
      getmaxcustomer_centerwiseid(value);
    }

    setFormData(updatedFormData);
  };

  function CustomerMasterDataUpdateonCenteridchange(centerid = null, routename = null){
    var urllink='https://reactfadke.crmwindow.com/api/public/CustomerMaster/GetCustomerMasterDatabyCenteridAndRoutecode?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid+'&routename='+routename;

    axios.get(urllink)
        .then((response) => {

          // Access the response data directly using response.data
          setCustomerMasterData(response.data.CustomerMasterData);
        })
        .catch((error) => console.error('Error fetching data:', error));
  }

  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {

      if(UserMasterDataGlobal?.[0]?.usertype  !== "ADMIN" &&  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"){
        var urllink='https://reactfadke.crmwindow.com/api/public/CustomerMaster?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+UserMasterDataGlobal?.[0]?.centerid;
      } else {
        var urllink='https://reactfadke.crmwindow.com/api/public/CustomerMaster?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+UserMasterDataGlobal?.[0]?.centerid;
      }

      axios.get(urllink)
        .then((response) => {
          // Access the response data directly using response.data
          setCustomerMasterData(response.data.CustomerMasterData);
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(`https://reactfadke.crmwindow.com/api/public/CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)

      .then((response) => {
        // Access the response data directly using response.data
        setCenterMasterData(response.data.CenterMasterData);
        setdefaultcenter(response.data.CenterMasterData[0].centerid);
        if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
          setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
          setFormData({
            ...formData,
            centerid: UserMasterDataGlobal?.[0]?.centerid,
          })
        }
        getmaxcustomer_centerwiseid(response.data.CenterMasterData[0].centerid);
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);


  //2) using axios
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0 && defaultcenter) {
      setRouteMasterData([]);
      const axiostoapi = `https://reactfadke.crmwindow.com/api/public/RouteMaster/GetRouteByCenterIdAndCompanyId/${UserMasterDataGlobal[0]?.CompanyId}/${defaultcenter}`;
      axios.get(axiostoapi)
        .then((response) => {
          setRouteMasterData(response.data.RouteMasterData);
          CustomerMasterDataUpdateonCenteridchange(defaultcenter, response.data.RouteMasterData[0].routename);
        })
        .catch((error) => console.error('Error fetching route data:', error));
    }
  //we put defaultcenter below because when its value changes everytime this routemaster useeffect code will get executed
  }, [UserMasterDataGlobal, defaultcenter]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    
    var validationerrors = {};

    if(formData.get('customer_centerwiseid').trim() !== '') {
    }else{
      validationerrors.customer_centerwiseid = "Please enter a valid customer_centerwiseid.";
    }   

    if(formData.get('customer_name').trim() !== '') {
    }else{
      validationerrors.customer_name = "Please enter a valid Customer Name";
    }

    if(formData.get('customer_place').trim() !== '') {
    }else{
      validationerrors.customer_place = "Please enter a valid Customer Address";
    }

    if(formData.get('customer_mobile').trim() !== '') {
      if(formData.get('customer_mobile').length !== 10){
        validationerrors.customer_mobile = "Please enter a valid mobile number.";
      }
    }else{
    }

    if(formData.get('whatsapp_no').trim() !== '') {
      if(formData.get('whatsapp_no').length !== 10){
        validationerrors.whatsapp_no = "Please enter a valid whatsapp mobile number.";
      }
    }else{
    }

    if(formData.get('cowratib_qty').trim() !== '') {
      if(!isNaN(formData.get('cowratib_qty')) && parseFloat(formData.get('cowratib_qty')) >= 0) {  
      }
      else{
        validationerrors.cowratib_qty = "Please enter a valid positive numeric value for Cow Ltrs.";
      }
    }
    else{
    }

    if(formData.get('cowratib_rate').trim() !== '') {
      if(!isNaN(formData.get('cowratib_rate')) && parseFloat(formData.get('cowratib_rate')) >= 0) {
      }
      else{
        validationerrors.cowratib_rate = "Please enter a valid positive numeric value for Cow Rate.";
      }
    }
    else{
    }

    if(formData.get('cowratib_qty2').trim() !== '') {
      if(!isNaN(formData.get('cowratib_qty2')) && parseFloat(formData.get('cowratib_qty2')) >= 0) {  
      }
      else{
        validationerrors.cowratib_qty2 = "Please enter a valid positive numeric value for Cow Ltrs.";
      }
    }
    else{
    }

    if(formData.get('buffratib_qty').trim() !== '') {
      if(!isNaN(formData.get('buffratib_qty')) && parseFloat(formData.get('buffratib_qty')) >= 0) {
      }
      else{
        validationerrors.buffratib_qty = "Please enter a valid positive numeric value for Buff Ltrs.";
      }
    }
    else{
    }

    if(formData.get('buffratib_rate').trim() !== '') {
      if(!isNaN(formData.get('buffratib_rate')) && parseFloat(formData.get('buffratib_rate')) >= 0) {
      }
      else{
        validationerrors.buffratib_rate = "Please enter a valid positive numeric value for Buff Rate.";
      }
    }
    else{
    }

    if(formData.get('buffratib_qty2').trim() !== '') {
      if(!isNaN(formData.get('buffratib_qty2')) && parseFloat(formData.get('buffratib_qty2')) >= 0) {
      }
      else{
        validationerrors.buffratib_qty2 = "Please enter a valid positive numeric value for Buff Ltrs.";
      }
    }
    else{
    }

    if(formData.get('servicecharges').trim() !== '') {
      if(!isNaN(formData.get('servicecharges')) && parseFloat(formData.get('servicecharges')) >= 0) {
      }
      else{
        validationerrors.servicecharges = "Please enter a valid positive numeric value for Service charges.";
      }
    }
    else{
    }

    if(formData.get('opbal').trim() !== '') {
      if(!isNaN(formData.get('opbal'))) {
      }
      else{
        validationerrors.opbal = "Please enter a valid positive numeric value for Opening balance.";
      }
    }
    else{
    }

    if(formData.get('deposit_amt').trim() !== '') {
      if(!isNaN(formData.get('deposit_amt'))) {
      }
      else{
        validationerrors.deposit_amt = "Please enter a valid positive numeric value for Deposit amount.";
      }
    }
    else{
    }

    if(formData.get('deposit_receiptno').trim() !== '') {
      if(!isNaN(formData.get('deposit_receiptno'))) {
      }
      else{
        validationerrors.deposit_receiptno = "Please enter a valid positive numeric value for Deposit receipt no.";
      }
    }
    else{
    }

    seterrors(validationerrors);

    var centerid1='';
    if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
      centerid1 = UserMasterDataGlobal?.[0]?.centerid;
    }
    else {
      centerid1 = formData.get('centerid');
    }
    
    if(formData.get('customer_type') == "Alternate Subscription") {
      cowratib_qty2 = formData.get('cowratib_qty2').trim();
      buffratib_qty2 = formData.get('buffratib_qty2').trim();
    }

    if(Object.keys(validationerrors).length == 0) {
      // alert('no validation message');
      // return;
      var data = {
        CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
        userid: UserMasterDataGlobal?.[0]?.userid,
        centerid: centerid1,
        customer_name: formData.get('customer_name').trim(),
        customer_centerwiseid: formData.get('customer_centerwiseid').trim(),
        customer_place: formData.get('customer_place'),
        routename: formData.get('routename'),
        customer_mobile: formData.get('customer_mobile').trim(),
        whatsapp_no: formData.get('whatsapp_no').trim(),
        customer_type: formData.get('customer_type'),
        cowratib_qty: formData.get('cowratib_qty').trim(),
        cowratib_rate: formData.get('cowratib_rate').trim(),
        cowratib_qty2: cowratib_qty2,
        buffratib_qty: formData.get('buffratib_qty').trim(),
        buffratib_rate: formData.get('buffratib_rate').trim(),
        buffratib_qty2: buffratib_qty2,
        opbal: formData.get('opbal').trim(),
        servicecharges: formData.get('servicecharges').trim(),
        customer_status: formData.get('customer_status'),
        operationtype: formData.get('operationtype'),
        customer_mp_id: formData.get('customer_mp_id_hidden'),
        deposit_amt: formData.get('deposit_amt'),
        deposit_receiptno: formData.get('deposit_receiptno'),
        deposit_date: formData.get('deposit_date'),
      };

      if(data.operationtype == 'Add Customer'){
        
        const response = await axios.post('https://reactfadke.crmwindow.com/api/public/CustomerMaster/insert', data);

        if (response.status === 200) {

              if(!isNaN(+response.data)){
                  MySwal.fire({
                    html: <b>Record Saved Sucessfully!</b>,
                    icon: 'success'
                  })
                  data.clbal = data.opbal;
                  const newDataItem = {
                    ...data,
                    customer_mp_id: response.data,
                  };
          
                  setCustomerMasterData((prevData) => {
                    return [newDataItem, ...prevData];
                  });
                  
                  emptyinputs();
              } else if (response.data==="ccwid_present"){
                
                MySwal.fire({ 
                //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>  This Customer CenterWise ID already present. Try saving the record using
                  another Customer CenterWise ID</b>,
                  icon: 'warning'
                })
              
              } else if (response.data==="ccwname_present"){
                
                MySwal.fire({ 
                //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>  This Customer name already present. can't save this record...</b>,
                  icon: 'warning'
                })              
              }
        }
      }
      else
      {
        const response = await axios.post('https://reactfadke.crmwindow.com/api/public/CustomerMaster/update', data);
        // const response2 = await axios.post('https://reactfadke.crmwindow.com/api/public/CustomerMaster/update', data);
        
        if (response.status === 200) {
          // The data was successfully saved
          // You can display a success message to the user
              if(response.data.message == "Sucesss"){
                  MySwal.fire({
                  //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>Record updated Sucessfully!</b>,
                  icon: 'success'
                  })
                  
                  setCustomerMasterData((prevData) => {
                    const updatedData = [...prevData];
                    const index = updatedData.findIndex((record) => record.customer_mp_id == data.customer_mp_id);
  
                    if (index !== -1) {
                      updatedData[index] = { ...updatedData[index], ...data };
                    }           
                      
                    return updatedData;
                  });
                  emptyinputs();
              } else if (response.data==="ccwname_present"){
                
                MySwal.fire({ 
                //   title: <strong>Record Saved Sucessfully!</strong>,
                  html: <b>  This Customer name already present. can't save this record...</b>,
                  icon: 'warning'
                })
              
              }

          } else {
            // There was an error saving the data
            // You can display an error message to the user
            // console.log(response);
          }
      }
    } else{
      console.log("Validation error exists");
    }

  } 

  function confirmDelete(event, id) {
      event.preventDefault();

      MySwal.fire({
          title: 'Are you sure you want to delete the record?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          if (result.isConfirmed) {
              if (result.value) {
              // window.location.href = $(this).attr('href');
              deleteData(id);
              }
          }
      })
  }

  async function deleteData(id) {

    const response = await axios.delete(`https://reactfadke.crmwindow.com/api/public/CustomerMaster/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);
    
    if (response.status === 200) {
      // The record was deleted successfully.
      // console.log('Record deleted successfully!');
        if(response.data==="Sucessfully deleted") {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record deleted Sucessfully!</b>,
            icon: 'success'
            })
            setCustomerMasterData((prevData) => prevData.filter((record) => record.customer_mp_id !== id));
        } else if(response.data == "found") {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>This customer has transactions; so can't delete this account</b>,
              icon: 'warning'
          })        
        } 
  } else {
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record not deleted! Please try once again or call the developer</b>,
            icon: 'error'
        })
    }
  }

  function getdatabyrecordarray(record) {

    setdefaultcityname(record.customer_place);
    setdefaultcenter(record.centerid);
    setdefaultroutename(record.routename);

    setFormData({
      centerid: record.centerid,
      customer_mp_id: record.customer_mp_id,
      customer_centerwiseid: record.customer_centerwiseid,
      customer_name: record.customer_name,
      centerName: record.customer_place,
      customer_place: record.customer_place,
      customer_mobile: record.customer_mobile,
      whatsapp_no: record.whatsapp_no,
      customer_type: record.customer_type,
      routename: record.routename,
      cowratib_qty: record.cowratib_qty,
      cowratib_rate: record.cowratib_rate,
      cowratib_qty2: record.cowratib_qty2,
      buffratib_qty: record.buffratib_qty,
      buffratib_rate: record.buffratib_rate,
      buffratib_qty2: record.buffratib_qty2,
      opbal: record.opbal,
      clbal: record.clbal,
      servicecharges: record.servicecharges,
      customer_status: record.customer_status,
      operationtype: 'Edit Customer',
      deposit_amt: record.deposit_amt,
      deposit_receiptno: record.deposit_receiptno,
      deposit_date: record.deposit_date.slice(0, 10),
    });
    // following code is to go to top of the page after clicking on edit button.
    window.scrollTo(0, 0)
  }

  const customer_type_array = ['Daily Subscription', 'Alternate Subscription', 'Retailer', 'Wholesaler'];

  // Check your condition here, e.g., if the selected option is 'Wholesaler'
  const customer_type_condition = formData.customer_type ;

  // Create a copy of the options array and reorder it based on the condition
  const customer_type_options = [...customer_type_array];
  if (customer_type_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex2 = customer_type_options.indexOf(formData.customer_type);
    customer_type_options.splice(inactiveIndex2, 1); // Remove 'Inactive'
    customer_type_options.unshift(formData.customer_type); // Add 'Inactive' at the beginning
  }

  const customer_status_array = ['ACTIVE', 'DEACTIVE'];

  // Check your condition here, e.g., if the selected option is 'DAIRY-OWNER'
  const customer_status_condition = formData.customer_status;

  // Create a copy of the options array and reorder it based on the condition
  const customer_status_options = [...customer_status_array];
  if (customer_status_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex3 = customer_status_options.indexOf(formData.customer_status);
    customer_status_options.splice(inactiveIndex3, 1); // Remove 'Inactive'
    customer_status_options.unshift(formData.customer_status); // Add 'Inactive' at the beginning
  }

  function emptyinputs(){
    setFormData({
      centerid: '',
      customer_mp_id: '',
      customer_centerwiseid: '',
      customer_name: '',
      customer_place: '',
      customer_mobile: '',
      whatsapp_no: '',
      routename: '',
      customer_status: '',
      customer_type: '',
      cowratib_qty: '',
      cowratib_rate: '',
      cowratib_qty2: '',
      buffratib_qty: '',
      buffratib_rate: '',
      buffratib_qty2: '',
      opbal: '',
      clbal: '',
      servicecharges: '',
      operationtype: 'Add Customer',
      customer_mp_id_hidden: '',
      deposit_amt: '',
      deposit_receiptno: '',
      deposit_date: '',
    });
  }

  const getCenterNameById = (centerid) => {
    const center = CenterMasterData.find(center => center.centerid === centerid);
    return center ? center.centername : '';
  };

  const [defaultcityname, setdefaultcityname] = useState();
  const [defaultroutename, setdefaultroutename] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = CustomerMasterData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(CustomerMasterData.length / recordsPerPage);
  //const numbers = [...Array(npage + 1).keys()].slice(1);
  const getVisiblePageNumbers = (currentPage, totalPages) => {
    // Handle cases with 5 or fewer pages
    if (totalPages <= 5) {
      return [...Array(totalPages + 1).keys()].slice(1);
    }
  
    // Handle cases with more than 5 pages
    const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
    let gap = false;
    const finalNumbers = [];
  
    for (let i = 1; i <= totalPages; i++) {
      if (visiblePages.includes(i)) {
        finalNumbers.push(i);
        gap = false;
      } else if (!gap) {
        finalNumbers.push('...');
        gap = true;
      }
    }
  
    return finalNumbers;
  };
  
  const numbers = getVisiblePageNumbers(currentPage, npage);

  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');
    navigate('/loginpage');
  }

  function getclbalvalue() {
    if(formData.operationtype != "Edit Customer") {
      setFormData({
        ...formData,
        clbal: formData.opbal,
      })
    }
  }

  const [AlternateSubscriptionVisible, setAlternateSubscriptionVisible] = useState(false);

  useEffect(() => {
    // Check initial value of customer_type and set AlternateSubscriptionVisible accordingly
    if(formData.customer_type !== "Alternate Subscription"){
      setAlternateSubscriptionVisible(true); // Change to false when value is not "Alternate Subscription"
    } else {
      setAlternateSubscriptionVisible(false);
    }
  }, [formData.customer_type]); // Re-run effect when formData.customer_type changes

  async function getmaxcustomer_centerwiseid(centerid = null) {
    
    const axiosreq = `https://reactfadke.crmwindow.com/api/public/CustomerMaster/GetMaxCustomer_centerwiseidbycenterno/${UserMasterDataGlobal?.[0]?.CompanyId}/${centerid}`;

    axios.get(axiosreq)

    .then((response) => {
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid:response.data,
      }));
    })
    .catch((error) => console.error('Error fetching data:', error));
  }

  return (

    <div className="container-fluid" style={{color: '#4229cb', fontSize:18, fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Customer Master</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', backgroundColor: 'lightgreen', marginTop:'-8px'}}>
          <a className="navbar-brand" href="/" >Home</a>
          {/* <a className="navbar-brand" href="/"style={{ marginLeft: "-270px" }}>Back to Order Sheet</a> */}
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
          </div>
      </nav>

    <div className="row" styles={{backgroundColor: 'lightgreen'}}>
      <div className="col-md-12">
        <form onSubmit={handleSubmit} method="POST" id="form1">
          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-centerName" className="form-label">Select Center Name</label>

                <select
                  className="form-select"
                  id="id-centerid"
                  name="centerid" // <-- should match `formData.uom1` property
                  value={formData.centerid}
                  onChange={handleInputChange}
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>

            </div>
            <div className="col-md-2">
              <label htmlFor="id-customer_centerwiseid" className="form-label">Center Wise Cust Id</label>
              <input type="number" className="form-control" name="customer_centerwiseid" id="id-customer_centerwiseid"  value={formData.customer_centerwiseid}  onChange={handleInputChange}
              placeholder='Type here' required />
              {errors.customer_centerwiseid && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_centerwiseid}</span>}
            </div>
            <div className="col-md-3">
              <label htmlFor="id-customer_name" className="form-label">Customer Name</label>
              <input type="text" className="form-control" name="customer_name" id="id-customer_name" value={formData.customer_name} onChange={handleInputChange}
               placeholder='Type here' autoComplete='off' required />
              {errors.customer_name && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_name}</span>}
            </div>

            <div className="col-md-3">
              <label htmlFor="id-customer_place" className="form-label">Address</label>              
              <input type="text" className="form-control" name="customer_place" value={formData.customer_place} onChange={handleInputChange} placeholder='Type here' />
              {errors.customer_place && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_place}</span>}
            </div>
            <div className="col-md-2">
              <label htmlFor="id-routename" className="form-label">Line No</label>
              <select
                className="form-select"
                id="id-routename" 
                name="routename"
                value={formData.routename}
                onChange={handleInputChange}
              >

              {defaultroutename && (
                <option value={defaultroutename}>
                  {defaultroutename}
                </option>
              )}

              {RouteMasterData.map((r) => (
                 defaultroutename !== r.routename ?
                 <option key={r.routecode} value={r.routename}>
                   {r.routename}
                 </option>
                  : null
              ))}
              </select>
            </div>

          </div>

          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-customer_mobile" className="form-label">Mobile No</label>
              <input type="number" className="form-control" name="customer_mobile" id="id-customer_mobile" value={formData.customer_mobile} onChange={handleInputChange} placeholder='Type here' />
              {errors.customer_mobile && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_mobile}</span>}
            </div>
            <div className="col-md-2">
              <label htmlFor="whatsapp_no" className="form-label">Whatsapp No</label>
              <input type="number" className="form-control" name="whatsapp_no" value={formData.whatsapp_no} onChange={handleInputChange} placeholder='Type here' />
              {errors.whatsapp_no && <span style={{color: 'red', fontSize: '10px'}}>{errors.whatsapp_no}</span>}
            </div>            
            <div className="col-md-3">
              <label htmlFor="id-customer_type" className="form-label">Subscription Type</label>
              <select
              name="customer_type"
              className="form-select"
              id="id-customer_type"
              value={formData.customer_type}
              onChange={handleInputChange} >
              {customer_type_options.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
              </select>
            </div>

          </div>         
            
            {AlternateSubscriptionVisible ? (
              <>
              <div className="row mb-3">
                <div className="col-md-2">
                  <label htmlFor="cowratib_qty" className="form-label">Cow Ltrs.</label>
                  <input type="text" className="form-control" name="cowratib_qty" value={formData.cowratib_qty} onChange={handleInputChange} placeholder='0.00' />
                  <input type="hidden" className="form-control" name="cowratib_qty2" value={formData.cowratib_qty2} onChange={handleInputChange} placeholder='0.00' /> 
                  {errors.cowratib_qty1 && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowratib_qty1}</span>}
                </div>
                <div className="col-md-2">
                  <label htmlFor="cowratib_rate" className="form-label">Cow Rate Rs.</label>
                  <input type="text" className="form-control" name="cowratib_rate" id="id-cowratib_rate" value={formData.cowratib_rate} onChange={handleInputChange}  placeholder='0.00' />
                  {errors.cowratib_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowratib_rate}</span>}
                </div>

                <div className="col-md-2">
                  <label htmlFor="id-buffratib_qty" className="form-label">Buff Ltrs.</label>
                  <input type="text" className="form-control" name="buffratib_qty" id="id-buffratib_qty" value={formData.buffratib_qty} onChange={handleInputChange} placeholder='0.00' />
                  <input type="hidden" className="form-control" name="buffratib_qty2" id="id-buffratib_qty2" value={formData.buffratib_qty2} onChange={handleInputChange} placeholder='0.00' />
                  {errors.buffratib_qty && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffratib_qty}</span>}
                </div>
                <div className="col-md-2">
                  <label htmlFor="id-buffratib_rate" className="form-label">Buff Rate Rs</label>
                  <input type="text" className="form-control" name="buffratib_rate" id="id-buffratib_rate" value={formData.buffratib_rate} onChange={handleInputChange}  placeholder='0.00' />
                  {errors.buffratib_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffratib_rate}</span>}
                </div>
                <div className="col-md-2">
                  <label htmlFor="id-servicecharges" className="form-label">Service Charges Rs. </label>
                  <input type="text" className="form-control" name="servicecharges" id="id-servicecharges" value={formData.servicecharges} onChange={handleInputChange}  placeholder='0.00' />
                  {errors.servicecharges && <span style={{color: 'red', fontSize: '10px'}}>{errors.servicecharges}</span>}
                </div>
              </div>
              </>         

          ) : (
            <>
              <div className="row mb-3">
                
                <div className="col-md-2">       
                <label className="form-label">Odd-Even day Cow Ltrs</label>           
                  <div className='row'>
                    <div className='col-md-6'>
                      <input type="text" className="form-control" name="cowratib_qty" value={formData.cowratib_qty} onChange={handleInputChange} placeholder='0.00' />
                    </div>
                    <div className='col-md-6'>
                      <input type="text" className="form-control" name="cowratib_qty2" value={formData.cowratib_qty2} onChange={handleInputChange} placeholder='0.00' />
                    </div>
                  </div>
                </div>
                {errors.cowratib_qty && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowratib_qty}</span>}
                {errors.cowratib_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowratib_qty2}</span>}

                <div className="col-md-2">
                  <label className="form-label">Cow rate</label>
                  <input type="text" className="form-control" name="cowratib_rate" value={formData.cowratib_rate} onChange={handleInputChange} placeholder='0.00' />
                </div>
                {errors.cowratib_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.cowratib_rate}</span>}

                <div className="col-md-2">
                  <label className="form-label">Odd-Even day Buff Ltrs</label>
                  <div className='row'>
                    <div className='col-md-6'>
                      <input type="text" className="form-control" name="buffratib_qty" value={formData.buffratib_qty} onChange={handleInputChange} placeholder='0.00' />
                    </div>
                    <div className='col-md-6'>
                      <input type="text" className="form-control" name="buffratib_qty2" value={formData.buffratib_qty2} onChange={handleInputChange} placeholder='0.00' />
                    </div>
                  </div>
                </div>
                {errors.buffratib_qty && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffratib_qty}</span>}
                {errors.buffratib_qty2 && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffratib_qty2}</span>}

                <div className="col-md-2">
                  <label className="form-label">Buff rate</label>
                  <input type="text" className="form-control" name="buffratib_rate" value={formData.buffratib_rate} onChange={handleInputChange} placeholder='0.00' />
                </div>
                {errors.buffratib_rate && <span style={{color: 'red', fontSize: '10px'}}>{errors.buffratib_rate}</span>}

                <div className="col-md-2">
                  <label htmlFor="id-servicecharges" className="form-label">Service Charges Rs. </label>
                  <input type="text" className="form-control" name="servicecharges" id="id-servicecharges" value={formData.servicecharges} onChange={handleInputChange}  placeholder='0.00' />
                  {errors.servicecharges && <span style={{color: 'red', fontSize: '10px'}}>{errors.servicecharges}</span>}
                </div>

              </div>
            </>
         
          )}

          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-customer_status" className="form-label">Customer Status</label>
             
              <select
              name="customer_status"
              className="form-select"
              id="id-customer_status"
              value={formData.customer_status}
              onChange={handleInputChange} >
              {customer_status_options.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
              </select>
            </div>

            <div className="col-md-2">
              <label htmlFor="id-opbal" className="form-label">Opening balance </label>
              <input type="text" className="form-control" name="opbal" id="id-opbal" value={formData.opbal} onChange={handleInputChange}  onBlur={getclbalvalue} placeholder='0.00' />
              {errors.opbal && <span style={{color: 'red', fontSize: '10px'}}>{errors.opbal}</span>}
            </div>

            <div className="col-md-2">
              <label htmlFor="id-clbal" className="form-label">Closing balance </label>
              <input type="text" className="form-control" name="clbal" id="id-clbal" value={formData.clbal} onChange={handleInputChange}  placeholder='0.00' readOnly/>
            </div>

            <div className="col-md-2">
              <label htmlFor="id-deposit_amt" className="form-label">Deposit amt </label>
              <input type="text" className="form-control" name="deposit_amt" id="id-deposit_amt" value={formData.deposit_amt} onChange={handleInputChange}  placeholder='0.00'/>
              {errors.deposit_amt && <span style={{color: 'red', fontSize: '10px'}}>{errors.deposit_amt}</span>}
            </div>

            <div className="col-md-2">
              <label htmlFor="id-deposit_receiptno" className="form-label">Deposit receipt no </label>
              <input type="text" className="form-control" name="deposit_receiptno" id="id-deposit_receiptno" value={formData.deposit_receiptno} onChange={handleInputChange}  placeholder='0.00'/>
              {errors.deposit_receiptno && <span style={{color: 'red', fontSize: '10px'}}>{errors.deposit_receiptno}</span>}
            </div>

            <div className="col-md-2">
              <label htmlFor="id-deposit_date" className="form-label">Deposit Date </label>
              <input type="date" className="form-control" name="deposit_date" id="id-deposit_date" value={formData.deposit_date} onChange={handleInputChange} />
            </div>
          </div>

          <input type="hidden" className="form-control" name="customer_mp_id_hidden" id="id-customer_mp_id_hidden" value={formData.customer_mp_id}  />
          <input type="hidden" className="form-control" name="operationtype" id="id-operationtype" value={formData.operationtype}  />

          <button type="submit" className="btn btn-primary">{formData.operationtype == 'Add Customer' ? 'Add Customer' : 'Edit Customer'}</button>
        </form>
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-md-12 table-responsive-xl">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>CenterName</th>
              <th>Line</th>
              <th>Place</th>
              <th style={{textAlign: 'center'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {records.map((c, index) => (
            <tr key={c.customer_mp_id}>
              <td>{c.customer_centerwiseid}</td>
              <td>{c.customer_name}</td>
              <td>{getCenterNameById(c.centerid)}</td>
              <td>{c.routename}</td>
              <td>{c.customer_place}</td>
              <td style={{textAlign: 'center'}}>
                <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
                {/* <Link to={generatePath(routes.customerDetails, { customerid1: c.customer_mp_id })}  className="btn btn-success btn-sm">edit</Link> */}

                &nbsp;<button onClick={(event) => confirmDelete(event, c.customer_mp_id)} className="btn btn-danger btn-sm ml-1">
                Delete
                </button>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        
        <nav style={{float: 'right' }}>
          <ul className='pagination'>
              <li className='page-item'>
                <a href='#' className='page-link'
                onClick={prePage}>Prev</a>
              </li>
              
              {
                numbers.map((n, i) => (
                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                  {n === '...' ? (
                    <span className="page-link disabled">...</span>
                  ) : (
                    <a href="#" className="page-link" onClick={() => ChangeCpage(n)}>
                      {n}
                    </a>
                  )}
                </li>
              ))}

              <li className='page-item'>
                <a href='#' className='page-link'
                onClick={nextPage}>Next</a>
              </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  
  );

  function prePage() {
    if(currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function ChangeCpage(id) {
    setCurrentPage(id)
  }

  function nextPage() {
    if(currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }

}

export default CustomerMaster;
