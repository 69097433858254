import React, { useEffect, useState } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';

function CustomerMaster() {

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

    const navigate = useNavigate();
    // const usernameglobal='';
    
    const Redirectif = () => {
        useEffect(() => {
        if (usernameglobal == null) {
            navigate('/LoginPage');
        }
        }, [usernameglobal, navigate]);
    } 

    try {
        if (usertimestampglobal != null) {
        const parsedTimestamp = parseInt(usertimestampglobal, 10);
        const currentTime = new Date().getTime();
        const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

        if (parsedTimestamp < twelveHoursAgo) {
            localStorage.removeItem('usertimestamp');
            localStorage.removeItem('username');
            Redirectif();
        }
        }
    }
    catch {
        
    }
    
    if(usernameglobal == null){
        Redirectif();
        // return null; 
    }
    
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);

    useEffect(() => {
        // Fetch data from the specified URL
        axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)

            .then((response) => {
            setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [usernameglobal]);

    const MySwal = withReactContent(Swal);

    const handleSubmit = async (event) => {
        event.preventDefault();

    }

    function navigatetologin() {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        navigate('/loginpage');
    }

    const [formData, setFormData] = useState({
        centerno: '',
        centerid: '',
        customer_centerwiseid: '',
        customer_name: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [defaultcenter, setdefaultcenter] = useState();

    const getCenterNameById = (centerid) => {
        const center = CenterMasterData.find(center => center.centerid === centerid);
        return center ? center.centername : '';
    };

    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(`https://reactfadke.crmwindow.com/api/public/CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          // Access the response data directly using response.data
          setCenterMasterData(response.data.CenterMasterData);
          if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
            setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
            setFormData({
              ...formData,
              centerid: UserMasterDataGlobal?.[0]?.centerid,
            })
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const [customer_name_data,  setcustomer_name_data] =  useState([]);

    const centeridonblur = async (e) => {
        e.preventDefault();
        
        var { name, value } = e.target;
        var data = {
          routecode: value,
        }
        
        if (value.includes('-')) {
          // Split the value based on '-'
          const [customer_centerwiseid, customer_name] = value.split('-');
          value = customer_centerwiseid;
        }
        
        if(value == ''){
          return;
        }
    
        var centeridtopass='';
        if(UserMasterDataGlobal?.[0]?.usertype  !== "ADMIN" &&  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"){
          centeridtopass = UserMasterDataGlobal?.[0]?.centerid;
        }
        else{
          centeridtopass = value;
        }
    
        const response = await axios.get('https://reactfadke.crmwindow.com/api/public/CustomerMaster/getrecordbybycenterid?centerid='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
    
        .then((response) => {
          // Access the response data directly using response.data
          if(response.data.CustomerMasterData.length > 0){
            setcustomer_name_data(response.data.CustomerMasterData);
            // setFormData({
            //   ...formData,
            //   prevbalrs: response.data.CustomerMasterData[0].clbal,
            // });
                
          } else {
            // if(formData.customer_centerwiseid != '') {
            //   alert("Apologies, no customer found; they may be located at a different center...");
            //   setFormData({
            //     ...formData,
            //     customer_centerwiseid: '',
            //     routecode: '',
            //   });
            //   setcustomer_name_data([]);
            // }        
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      };

    return(
        
    <div className="container" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Customer Master</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">Home</a>
          <ul className="navbar-nav">
              <li className="nav-item">
                <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
        </div>
      </nav>

    <div className="row" styles={{backgroundColor: 'lightgreen'}}>
      <div className="col-md-6 offset-md-3">
        <form onSubmit={handleSubmit} method="POST" id="form1">
            <div className="row mb-3">
                <div className="col-md-5">
                    <label htmlFor="id-centerno" className="form-label">Center No</label>
                    <input type="number" className="form-control" name="centerno" id="id-centerno"  value={formData.centerno}  onChange={handleInputChange}
                    placeholder='Type here' required />
                </div>
                <div className="offset-md-1 col-md-5">
                    <label htmlFor="id-centername" className="form-label">Center Name</label>
                    
                    <select
                    className="form-select"
                    id="id-centerid"
                    name="centerid" // <-- should match `formData.uom1` property
                    value={formData.centerid}
                    onChange={handleInputChange}
                    onBlur={centeridonblur}
                    disabled={
                        UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                        UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                    }
                    >
                    {/* uom option from selected record when available */}
                    {defaultcenter && (
                    <option key={defaultcenter} value={defaultcenter}>
                        {getCenterNameById(defaultcenter)}
                    </option>
                    )}

                    {/* dynamically fetched options */}
                    {CenterMasterData.map((cp) => (
                        defaultcenter !== cp.centerid ?
                            <option key={cp.centerid} value={cp.centerid} >
                            {cp.centername}
                            </option>
                        : null
                    ))}
                </select>

                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-5">
                    <label htmlFor="id-customer_centerwiseid" className="form-label">Customer center wise ID</label>
                    <input type="number" className="form-control" name="customer_centerwiseid" id="id-customer_centerwiseid"  value={formData.customer_centerwiseid}  onChange={handleInputChange}
                    placeholder='Type here' required />
                </div>
                <div className="offset-md-1 col-md-5">
                    <label htmlFor="id-customername" className="form-label">Customer Name</label>
                    {/* <input type="text" className="form-control" name="customername" id="id-customername" defaultValue={formData.customername} onChange={handleInputChange} required/> */}
                    <select
                    name="customer_name"
                    className="form-select"
                    id="id-customer_name"
                    value={formData.customer_name}
                    onChange={handleInputChange} 
    
                    >
                    {Array.isArray(customer_name_data) && customer_name_data.map((option) => (
                        <option key={option.customer_centerwiseid}  value={`${option.customer_centerwiseid}-${option.customer_name}`} >{option.customer_name}</option>
                    ))}
                    </select>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-5">
                    <label htmlFor="id-fromdate" className="form-label">From Date</label>
                    <input type="date" className="form-control" name="fromdate" id="id-fromdate"  value={formData.fromdate}  onChange={handleInputChange}
                    required />
                </div>
                <div className="offset-md-1 col-md-5">
                    <label htmlFor="id-todate" className="form-label">To Date</label>
                    <input type="date" className="form-control" name="todate" id="id-todate"  value={formData.todate}  onChange={handleInputChange}
                    required />
                </div>
            </div>
        </form>
      </div>
    </div>                        
    
    {/* <div className="row mt-4">
      <div className="col-md-12 table-responsive-xl">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>CenterName</th>
              <th>Route</th>
              <th>Place</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {records.map((c, index) => (
            <tr key={c.customer_mp_id}>
              <td>{c.customer_centerwiseid}</td>
              <td>{c.customer_name}</td>
              <td>{getCenterNameById(c.centerid)}</td>
              <td>{c.routename}</td>
              <td>{c.customer_place}</td>
              <td>
                <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
              

                &nbsp;<button onClick={(event) => confirmDelete(event, c.customer_mp_id)} className="btn btn-danger btn-sm ml-1">
                Delete
                </button>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        
        
      </div>
    </div> */}

  </div>

    );
}

export default CustomerMaster;
