// // Import necessary React and Bootstrap components
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

function LoginPage() {

    const navigate = useNavigate();
    
    const Redirectif = () => {
        useEffect(() => {
            navigate('/');
        }, []);
    }

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    if(usernameglobal != null){
      Redirectif();
    }
    
    const MySwal = withReactContent(Swal);
    const [errors, seterrors] = useState({});

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form submission
        // Handle login logic here
        const formData = new FormData(event.target);
        
        var validationerrors = {};

        if(formData.get('username').trim() !== '') {
        }else{
            validationerrors.username = "Please enter a valid username.";
        }   

        if(formData.get('password').trim() !== '') {
        }else{
            validationerrors.password = "Please enter a valid password.";
        }   
        seterrors(validationerrors);
 
        if(Object.keys(validationerrors).length == 0) {
            const data = {
                username: formData.get('username').trim(),
                password: formData.get('password').trim()
            };
            const response = await axios.post('https://reactfadke.crmwindow.com/api/public/UserMaster/validate1', data);
            
            if (response.status === 200) {
              
                if(response.data == "found"){
                    // MySwal.fire({
                    //     html: <b>Login Successfull!</b>,
                    //     icon: 'success'
                    // })
                    const now = new Date().getTime();
                    localStorage.setItem('username', JSON.stringify(data.username));
                    localStorage.setItem('usertimestamp', JSON.stringify(now));
                    navigate('/');
                }
                else if (response.data ==  "not found") {
                    MySwal.fire({
                        html: <b>Login Failed. Check your username and password again...</b>,
                        icon: 'error'
                    })
                }
                else if (response.data ==  "No username found!") {
                    MySwal.fire({
                        html: <b>No Customer found with this Username.</b>,
                        icon: 'warning',
                    })
                }
            }
        }
        else {
            console.log("validation errors...");
        }
    };

    return (
        // Add code here
        <div className="container vh-100 d-flex justify-content-center align-items-center">
        <div className="card text-center" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}}>
            <div className="card-header" style={{color: 'white', backgroundColor: 'rgb(66, 41, 203)', paddingTop:'15px'}}>
            <h3>मिल्कप्रो - MSDS</h3>
            </div>
            <div className="card-body">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                <label htmlFor="username" style={{float:'left'}}>Enter username:</label>
                <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={formData.username}                    
                    onChange={handleInputChange}
                />
                {errors.username && <span style={{color: 'red', fontSize: '10px'}}>{errors.username}</span>}
                </div>
                <div className="form-group">
                <label htmlFor="password" style={{float:'left'}}>Enter password:</label>
                <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formData.password}                    
                    onChange={handleInputChange}
                />
                {errors.password && <span style={{color: 'red', fontSize: '10px'}}>{errors.password}</span>}
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                Login
                </button>
            </form>
            </div>
        </div>
        </div>
    );
}

export default LoginPage;