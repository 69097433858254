import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function DailyDeliverySheet() {

    // to get data from with following labels from localstorage we use below code
    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));
    //sweet alert declaration
    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();
  
    const Redirectif = () => {
      useEffect(() => {
        if (usernameglobal == null) {
          navigate('/LoginPage');
        }
      }, [usernameglobal, navigate]);
    }
    
    try {
      if (usertimestampglobal != null) {
        const parsedTimestamp = parseInt(usertimestampglobal, 10);
        const currentTime = new Date().getTime();
        const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds
  
        if (parsedTimestamp < twelveHoursAgo) {
          localStorage.removeItem('usertimestamp');
          localStorage.removeItem('username');
          Redirectif();
        }
      }
    }
    catch {}
  
    if(usernameglobal == null){
      Redirectif();
    }
    
    //get usermaster data from its username by using following useeffect
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    useEffect(() => {
      axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)
          .then((response) => {
            setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
          })
          .catch((error) => console.error('Error fetching data:', error));
    }, [usernameglobal]);

    function navigatetologin() {
      localStorage.removeItem('usertimestamp');
      localStorage.removeItem('username');
      navigate('/loginpage');
    }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      if(name == "centerid") {
        const [centerid, centername] = value.split('-');
        updatedFormData.centerno = centerid;
        setdefaultcenter(centerid);
        // getroutemasterdata(centerid, 'd6');
        getroutemasterdatabycenterid(centerid, "centername");
      }

      if(name == "routename") {
        const [routecode, routename] = value.split('-');
        // updatedFormData.routecode = routecode;
        setdefaultroutename(routecode);
        fetchcustomer_name_data(formData.centerid, routecode, formData.bookdate);
      }

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      centerno: '',
      routecode: '',
      bookdate: '',
      centername: '',
      routename: '',
      centerid: '',
      customer_centerwiseid: '',
      customer_name: '',
      transactionid: '',
      customer_status: '',
    });

    const [defaultcenter, setdefaultcenter] = useState();
    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(`https://reactfadke.crmwindow.com/api/public/CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          setCenterMasterData(response.data.CenterMasterData);
          setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
          setFormData({
            ...formData,
            centerno: UserMasterDataGlobal?.[0]?.centerid,
            bookdate: getCurrentDate(),
          })
          getroutemasterdata(UserMasterDataGlobal?.[0]?.centerid, '2d');

          // if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
          //   setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
          //   setFormData({
          //     ...formData,
          //     centerno: UserMasterDataGlobal?.[0]?.centerid,
          //     bookdate: getCurrentDate(),
          //   })
          //   getroutemasterdata(UserMasterDataGlobal?.[0]?.centerid, '2d');
          // } 

        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    const getCenterNameById = (centerid) => {
      const center = CenterMasterData.find(center => center.centerid === centerid);
      return center ? center.centername : '';
    };

    const [defaultroutename, setdefaultroutename] = useState();
    const [RouteMasterData, setRouteMasterData] = useState([]);

    const routecodeonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;
  
      if (value.includes('-')) {
        // Split the value based on '-'
        const [routecode, routename] = value.split('-');
        value = routecode;
      }
  
      if(value == ''){
        return;
      }
  
      const response = await axios.get('https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebyroutecode?routecode='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)
   
      .then((response) => {
        // Access the response data directly using response.data       
        try {
          // formData.routename = response.data.customer_name_data[0].routename;
          setdefaultroutename(response.data.routenamedata[0].routename);
        }
        catch{

        }
        
      });   
    };
  
    const centernoonblur = async (e) => {
      e.preventDefault();
      var { name, value } = e.target;

      if(value == ''){
        return;
      }
      setdefaultcenter(value);
      formData.centerid = value;
      // getroutemasterdata(value, 'd1');
      getroutemasterdatabycenterid(value);
      fetchcustomer_name_data(value,defaultroutename, formData.bookdate); 
    };

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        bookdate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      temporaryvalues_table();
      
  }

  async function temporaryvalues_table(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(defaultroutename !== '') {
      }else{
        validationerrors.routecode = "Please select prefered line no from list.";
      } 

      if(formData.centerno !== '') {
      }else{
        validationerrors.centerno = "Please select prefered center from list.";
      } 

      if(formData.bookdate !== '') {
      }else{
        validationerrors.bookdate = "Please select date from datepicker.";
      } 

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {
      
        var data = {
          // centerno: defaultcenter || (UserMasterDataGlobal?.[0]?.centerid || ""),
          centerno:formData.centerno,
          routecode: defaultroutename,
          // routecode : formData.routecode ,
          bookdate: formData.bookdate || getCurrentDate(),
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId || "",
          userid: UserMasterDataGlobal?.[0]?.userid || "",
          customer_status: formData.customer_status !== "" ?  formData.customer_status : "ALL",
        };
        // alert(data.routecode + " at step1");
       
        setFormData({
          ...formData,
          centerno: data.centerno,
          routecode: data.routecode,
          bookdate: data.bookdate
        });
        // alert(data.routecode + " at step2");
        // return;
        setdefaultcenter(data.centerno);
        setdefaultroutename(data.routecode);

        try {
          var response  = "";
          if(toshowcustomermasterdata !== "notshow") {
            response = await axios.post('https://reactfadke.crmwindow.com/api/public/Reports/OutStandingReport', data);
            setCustomerMasterData([]);  
            if (response.status === 200) {
                // console.log(response.data);
                // return;
                setCustomerMasterData(response.data);                   
            }   
          }
        } catch {
        
        } 
      }  
  }

  const { state } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state) {
          setFormData({
            ...formData,
            centerno: state?.centerid,
            routecode: state?.routecode,
            bookdate: state?.transdate,
          });  
          // formref.current.submit(); 
          // console.log('s');
          // if (buttonref.current) {
          Swal.fire("Click on view button to see the latest record added!");
          // }
        }

      } catch (error) {
        console.error('Error fetching data for editing:', error);
      }
    };

    fetchData();
  }, [state]);
    
  // const [temporaryvalues_table_data, settemporaryvalues_table_data] = useState([]);
  
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
      
      const apirequest = `https://reactfadke.crmwindow.com/api/public/DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            setdefaultroutename(response.data[0].text3);
            setdefaultcenter(response.data[0].text1);

            setFormData({
              ...formData,
              routecode: response.data[0].text3,
              centerno: response.data[0].text1,
              bookdate: response.data[0].text5,
            });
            fetchcustomer_name_data(response.data[0].text1, response.data[0].text3, response.data[0].text5);
          }
          catch {

          }          

        })

        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  async function getroutemasterdata(centerid, rr) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = axios.get(`https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
    })
  }

  //this is the array mounted for populating customer_name list in its selectbox
  const [customer_name_data,  setcustomer_name_data] =  useState([]);

    //below function is for putting data in customer_name_data array
    async function  fetchcustomer_name_data(centerid, routecode, bookdate) {
      
      setcustomer_name_data([]);

      var axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/RouteMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
      +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid+'&bookdate='+bookdate;
      
      const response = axios.get(axiosreqtoapi)

      .then((response) => {
          if(response.data.customer_name_data.length > 0) {
              setcustomer_name_data(response.data.customer_name_data);
          }
      })
      .catch((error) => {
          console.error('Error fetching data:', error);
      });
    }

    //to get the data from centerid/centerno we use this function
    async function getroutemasterdatabycenterid(centerid, rr=null) {
      var data1 = {
          centerid : centerid,
          CompanyId : UserMasterDataGlobal[0]?.CompanyId,
      }
      var response1 = axios.get(`https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
      .then((response1) => {
          setdefaultroutename(response1.data[0].routename);
          setRouteMasterData(response1.data);
          if(rr=="centername") {
              //populating customer_name list using customer_name_data array by calling below function.
              //i put below function here because when page first time renders and we try to change
              //centername from selectbox. it is trying to take routename of previous center.
              fetchcustomer_name_data(centerid,response1.data[0].routename, formData.bookdate);
          }
      })
  }

    //These variables declared here to show Total of Qty and Amt at the last of table.
    var TotalOutStanding =0;
    var TotalCustomerCount =0;

  async function printContent (event) {
    event.preventDefault();

    temporaryvalues_table();
    
    const printWindow = window.open('', '_blank');

    printWindow.document.write(`
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
    <div class="table-container col-md-12 ">
      <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Outstanding Report</h1>
      
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="color: red;">Customer Id</th>
            <th style="color: red;">Customer Name</th>
            <th style="color: red;">Outstanding</th>
            <th style="color: red;">Mobile No</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.map((c, index) => `
          <tr key=${index}>
            <td>${c.customer_centerwiseid}</td>
            <td>${c.customer_name}</td>
            <td>${Number(c.clbal).toFixed(2)}</td>
            <td>${c.customer_mobile}</td>
          </tr>
        `).join('')}
                
        </tbody>
      </table>
    </div>
  `);
  

    printWindow.document.close();
    printWindow.print();
  };

    return(
            
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Outstanding list</h1>
        <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color:'black', backgroundColor:'#a0ffa3',marginTop:-8}}>
            <a className="navbar-brand" href="/">Home</a>
            <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
                </li>
            </ul>
            <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
          </div>
        </nav>
  
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">
              <div className="col-md-1">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="text" className="form-control" name="centerno" id="id-centerno" value={formData.centerno} onChange={handleInputChange}
                onBlur={centernoonblur} required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
              </div> 

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="centerid" className="form-label">Select Center</label>

                <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  // value={formData.centerid}
                  onChange={handleInputChange}
                  
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>
              </div>

              {/* <div className="col-md-2"> */}
                {/* <label htmlFor="id-routecode" className="form-label">Line No </label> */}
                <input type="hidden" className="form-control" name="routecode" value={defaultroutename} onChange={handleInputChange} 
                onBlur={routecodeonblur} required />
                
              {/* </div> */}

              <div className="col-md-1">
                <label htmlFor="id-routename" className="form-label">Select Line</label>
                <select
                  className="form-select"
                  id="id-routename" 
                  name="routename"
                  // value={formData.routename}
                  onChange={handleInputChange}
                >

                {defaultroutename && (
                  <option value={defaultroutename}>
                    {defaultroutename}
                  </option>
                )}

                {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                  <option key={r.routecode} value={`${r.routecode}-${r.routename}`} >
                    {r.routename}
                  </option>
                    : null
                ))}
                </select>
                {errors.routecode && <span style={{color: 'red', fontSize: '10px'}}>{errors.routecode}</span>}
              </div>

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="id-bookdate" className="form-label">Till Date</label>
                <input type="date" className="form-control" name="bookdate" id="id-bookdate" value={formData.bookdate} onChange={handleInputChange} required/>
                {errors.bookdate && <span style={{color: 'red', fontSize: '10px'}}>{errors.bookdate}</span>}
              </div>

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="customer_status" className="form-label">Status</label>
                <select className='form-select' name='customer_status' onChange={handleInputChange}>
                    <option value='ALL'>ALL</option>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='DEACTIVE'>DEACTIVE</option>
                </select>
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>
               
            </div> 
          </form>
      </div>


      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '6%'}}>Customer Id</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Customer Name</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Outstanding</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '9%'}}>Mobile No</th>
                </tr>
              </thead>
              <tbody>
              {CustomerMasterData.map((c, index) => {
                TotalOutStanding = Number(TotalOutStanding) + Number(c.clbal);
                TotalCustomerCount = TotalCustomerCount + 1;
                return (
                  <tr key={c.customer_mp_id}>
                    <td>{c.customer_centerwiseid}</td>
                    <td>{c.customer_name}</td>
                    <td style={{textAlign: 'right'}}>{Number(c.clbal).toFixed(2)}</td>
                    <td style={{textAlign: 'right'}}>{c.customer_mobile}</td> 
                  </tr>
                );
              })}

              </tbody>
            </table>
          </div>

          <div className="row mb-3">

             <div className='col-md-3 offset-md-2' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(41 113 203)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total Customers = {TotalCustomerCount}</span>
            </div>
            <div className='col-md-3 offset-md-1' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(255, 12, 12)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total outstanding = {Number(TotalOutStanding).toFixed(2)}</span>
            </div>
          </div>

        </div>
      </div>

    </div>

    );

}

export default DailyDeliverySheet;