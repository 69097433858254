import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

function CashReceipt () {

    return(
        <div className='container'>  
            {/* <img
            src={'http://localhost/fadke_react_codeigniter1/phadake%20symbol.jpg'}
            alt={`Phadake group`}
            style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: '10px' }}
            />   
            <div className='row'>            
                <h1 style={{color: 'red', fontSize: 40, fontWeight: 'bold', marginTop: 5, textAlign: 'center'}}>फडके ग्रुप </h1>
            </div>
            <div className='row'>
                <span style={{textAlign: 'center', fontWeight: 500}}>1785, SADASHIV PETH, PUNE - 411030</span>
            </div>
            <div className='row'>
                <span style={{textAlign: 'center', fontWeight: 500}}>OFFICE TEL : 020-24470562, 24482145</span>      
            </div>
            <hr />
            <div className='row'>
                <span style={{textAlign: 'center', fontSize: 14, fontWeight: 500}}>Office timings</span>
            </div>
            <div className='row'>
                <span style={{textAlign: 'center', fontSize: 13, fontWeight: 500}}>MONDAY - SATURDAY: 9:00 AM TO 12 NOON AND 4:30 PM TO 8:00 PM</span>
            </div>
            <div className='row mb-3'>
                <span style={{textAlign: 'center', fontSize: 13, fontWeight: 500}}>SUNDAY TIMING : 9:00 AM TO 12 NOON AND 4:30 PM TO 7:00 PM</span>
            </div> */}
            <div className='row'>
                <div className='col-md-8' >
                    <div style={{textAlign: 'center'}}>
                    <img
                    src={'http://localhost/fadke_react_codeigniter1/phadake%20symbol.jpg'}
                    alt={`Phadake group`}
                    style={{ maxWidth: '100px', height: '100px', marginLeft: '10px', marginBottom: 10 }}
                    />   
                    <h1 style={{color: 'red', fontSize: 40, fontWeight: 'bold', marginTop: 5}}>फडके ग्रुप </h1>
                    </div>
                    <div style={{textAlign: 'center'}}>
                    <span style={{fontWeight: 500}}>1785, SADASHIV PETH, PUNE - 411030</span>
                    </div>
                    <div style={{textAlign: 'center'}}>
                    <span style={{fontWeight: 500}}>OFFICE TEL : 020-24470562, 24482145</span>      
                    </div>
                    <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                    <div style={{textAlign: 'center'}}>
                    <span style={{fontSize: 14, fontWeight: 500}}>Office timings</span>
                    </div>
                    <div style={{textAlign: 'center'}}>
                    <span style={{fontSize: 13, fontWeight: 500}}>MONDAY - SATURDAY: 9:00 AM TO 12 NOON AND 4:30 PM TO 8:00 PM</span>
                    </div>
                    <div style={{textAlign: 'center'}}>
                    <span style={{fontSize: 13, fontWeight: 500}}>SUNDAY TIMING : 9:00 AM TO 12 NOON AND 4:30 PM TO 7:00 PM</span>
                    </div>
                </div>
            </div>
            
            <div className="table-container col-md-12 mt-3">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{color: 'red'}}>Payment by</th>
                            <td>Cash</td>
                            <th style={{color: 'red'}}>Payment type</th>
                            <td>Janata bank</td>
                        </tr>
                        <tr>
                            <th style={{color: 'red'}}>Receipt Date</th>
                            <td>05/03/2024</td>
                            <th style={{color: 'red'}}>Center Name</th>
                            <td>Head office</td>
                        </tr>
                        <tr>
                            <th style={{color: 'red'}}>Line No</th>
                            <td>17</td>
                            <th style={{color: 'red'}}>Customer Name</th>
                            <td>Mr. Joshi Suhas</td>
                        </tr>
                        <tr>
                            <th style={{color: 'red'}}>Receipt No</th>
                            <td>42425</td>
                            <th style={{color: 'red'}}>Amount</th>
                            <td>868.25</td>
                        </tr>
                        <tr>
                            <th style={{color: 'red'}}>Bank Name</th>
                            <td>JANATA SAH.</td>
                            <th style={{color: 'red'}}>Branch</th>
                            <td>Karve RD.</td>
                        </tr>
                        <tr>
                            <th style={{color: 'red'}}>Cheque No</th>
                            <td>399317</td>
                            <th style={{color: 'red'}}>Cheque date</th>
                            <td>04/03/2024</td>
                        </tr>
                    </thead>
                    <tbody>                        

                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CashReceipt;

