import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

function UserRegistrationForm() {
  
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

  const navigate = useNavigate();
  // const usernameglobal='';
  
  const Redirectif = () => {
    useEffect(() => {
      if (usernameglobal == null) {
        navigate('/LoginPage');
      }
    }, [usernameglobal, navigate]);
  }

  try {
    if (usertimestampglobal != null) {
      const parsedTimestamp = parseInt(usertimestampglobal, 10);
      const currentTime = new Date().getTime();
      const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

      if (parsedTimestamp < twelveHoursAgo) {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        Redirectif();
      }
    }
  }
  catch {
    
  }

  if(usernameglobal == null){
    Redirectif();
  }

  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);

  useEffect(() => {
    // Fetch data from the specified URL
    axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)

        .then((response) => {
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        })
        .catch((error) => console.error('Error fetching data:', error));
  }, [usernameglobal]);

  const MySwal = withReactContent(Swal);
  const [errors, seterrors] = useState({});
  const [defaultcenter, setdefaultcenter] = useState();
  const [defaultaccessto, setdefaultaccessto] = useState();

  const [formData, setFormData] = useState({
    userid: '',
    username: '',
    userpassword: '',
    mobile: '',
    password: '',
    usertype: '',
    accessto: '',
    centerid: '',
    user_status: '',
    operationtype: 'Add User',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [UserMasterData, setUserMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if(UserMasterDataGlobal.length>0) {
      axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)

      .then((response) => {
        // Access the response data directly using response.data
        setUserMasterData(response.data.UserMasterData);
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const [CenterMasterData, setCenterMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if(UserMasterDataGlobal.length>0){
      axios.get('https://reactfadke.crmwindow.com/api/public/CenterMaster/getcenterbycompanyid?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)

      .then((response) => {
          // Access the response data directly using response.data
        setCenterMasterData(response.data.CenterMasterData);
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    
    var validationerrors = {};

    if(formData.get('username').trim() !== '') {
      if (formData.get('username').includes(' ')) {
        validationerrors.username = 'Spaces are not allowed in username. Please retype your username without space.';
      }
    }else{
      validationerrors.username = "Please enter a valid username.";
    } 
    
    if(formData.get('mobile').trim() !== '') {
      if(formData.get('mobile').length !== 10){
        validationerrors.mobile = "Please enter a 10 digit valid mobile number.";
      }
    }else{
      validationerrors.mobile = "Please enter a valid mobile number.";
    }

    if(formData.get('userpassword').trim() !== '') {

      if (formData.get('userpassword').includes(' ')) {
        validationerrors.userpassword1 = 'Spaces are not allowed in password. Please retype your password without space.';
      }

      if(!(formData.get('userpassword').length >= 4 && formData.get('userpassword').length <= 20)) {
        validationerrors.userpassword2 = 'Password must be between 4 and 20 characters long.';
      }

      // if (!/[A-Z]/.test(formData.get('password'))) {
      //   validationerrors.password1 = 'Password must contain at least one uppercase letter.';
      // }
  
      // // Example: Check for lowercase letters
      // if (!/[a-z]/.test(formData.get('password'))) {
      //   validationerrors.password2 = 'Password must contain at least one uppercase letter.';
      // }
  
      // // Example: Check for numbers
      // if (!/\d/.test(formData.get('password'))) {
      //   validationerrors.password2 = 'Password must contain at least one number.';
      // }
  
      // // Example: Check for special characters
      // if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.get('password'))) {
      //   validationerrors.password3 = 'Password must contain at least one special character.';
      // }

    }else{
      validationerrors.userpassword = "Please enter a valid password."
    } 

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length == 0) {
          
      const data = {
        centerid : formData.get('centerid'),
        CompanyId : UserMasterDataGlobal?.[0]?.CompanyId,
        username : formData.get('username'),
        mobile : formData.get('mobile'),
        userpassword : formData.get('userpassword'),
        usertype : formData.get('usertype'),
        accessto : formData.get('accessto'),
        centerid : formData.get('centerid'),
        user_status : formData.get('user_status'),
        operationtype : formData.get('operationtype'),
        userid : formData.get('userid'),
      };

      if(data.operationtype == 'Add User'){
        const response = await axios.post('https://reactfadke.crmwindow.com/api/public/UserMaster/insert', data);
        // console.log(response.data);
        // return;
        if(response.status == 200) {
          if(!isNaN(+response.data)){
            MySwal.fire({ 
              //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b> Record saved successfully  </b>,
              icon: 'success'
            })            
            const newDataItem = {
              ...data,
              userid: response.data,
            };
            setUserMasterData((prevData) => {
              return [newDataItem, ...prevData];
            });
            emptyinputs()
          } 
          else if(response.data == "username_present") {
            MySwal.fire({ 
              //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b> The record cannot be saved under this username as it already exists within the records. Please attempt to save it using a distinct username.
                  </b>,
              icon: 'warning'
            })
          }
        }
      }
      else {

        const response = await axios.post('https://reactfadke.crmwindow.com/api/public/UserMaster/update', data);
        // console.log(response.data);
        // return;
        if(response.status == 200) {
          if(response.data=="Sucesss"){
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record updated Sucessfully!</b>,
            icon: 'success'
            })

            setUserMasterData((prevData) => {
              const updatedData = [...prevData];
              const index = updatedData.findIndex((record) => record.userid == data.userid);

              if (index !== -1) {
                updatedData[index] = { ...updatedData[index], ...data };
              }                  
              console.log(updatedData);
              return updatedData;
            });
            emptyinputs()
          }
        }

      }

    }
    else {
      console.log("has validation errors...");
    }
  }

  function getdatabyrecordarray(record) {
    // console.log(record);
    setFormData({
      userid: record.userid,
      username: record.username,
      mobile: record.mobile,
      userpassword: record.userpassword,
      usertype: record.usertype,
      accessto: record.accessto,
      centerid: record.centerid,
      user_status: record.user_status,
      operationtype: 'Edit User',
    });
    
    // following code is to go to top of the page after clicking on edit button.
    window.scrollTo(0, 0)
  }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = UserMasterData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(UserMasterData.length / recordsPerPage);
  //const numbers = [...Array(npage + 1).keys()].slice(1);

  const getVisiblePageNumbers = (currentPage, totalPages) => {
    // Handle cases with 5 or fewer pages
    if (totalPages <= 5) {
      return [...Array(totalPages + 1).keys()].slice(1);
    }

    // Handle cases with more than 5 pages
    const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
    let gap = false;
    const finalNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      if (visiblePages.includes(i)) {
        finalNumbers.push(i);
        gap = false;
      } else if (!gap) {
        finalNumbers.push('...');
        gap = true;
      }
    }
    return finalNumbers;
  };

  const numbers = getVisiblePageNumbers(currentPage, npage);

  const getCenterNameById = (centerid) => {
    const center = CenterMasterData.find(center => center.centerid === centerid);
    return center ? center.centername : '';
  };

  const user_status_array = ['ACTIVE', 'DEACTIVE'];

  // Check your condition here, e.g., if the selected option is 'Wholesaler'
  const user_status_condition = formData.user_status ;

  // Create a copy of the options array and reorder it based on the condition
  const user_status_options = [...user_status_array];
  if (user_status_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex2 = user_status_options.indexOf(formData.user_status);
    user_status_options.splice(inactiveIndex2, 1); // Remove 'Inactive'
    user_status_options.unshift(formData.user_status); // Add 'Inactive' at the beginning
  }

  const accessto_array = ['ALL DAIRY ACTIVITIES', 'COLLECTION', 
  'BILLING', 'OTHER TRANSACTIONS'];

  // Check your condition here, e.g., if the selected option is 'Wholesaler'
  const accessto_condition = formData.accessto ;

  // Create a copy of the options array and reorder it based on the condition
  const accessto_options = [...accessto_array];
  if (accessto_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex2 = accessto_options.indexOf(formData.accessto);
    accessto_options.splice(inactiveIndex2, 1); // Remove 'Inactive'
    accessto_options.unshift(formData.accessto); // Add 'Inactive' at the beginning
  }

  var usertype_array = [];
  if (UserMasterDataGlobal?.[0]?.usertype == "ADMIN") {
    usertype_array = ['OPERATOR'];
  } else {
    usertype_array = ['DAIRY OWNER', 'ADMIN', 'OPERATOR'];
  }  

  // Check your condition here, e.g., if the selected option is 'Wholesaler'
  const usertype_condition = formData.usertype ;
  // Create a copy of the options array and reorder it based on the condition
  const usertype_options = [...usertype_array];
  if (usertype_condition) {
    // If the condition is true, reorder the options (put 'Inactive' first)
    const inactiveIndex2 = usertype_options.indexOf(formData.usertype);
    usertype_options.splice(inactiveIndex2, 1); // Remove 'Inactive'
    usertype_options.unshift(formData.usertype); // Add 'Inactive' at the beginning
  }

  function confirmDelete(event, id) {
    event.preventDefault();

    MySwal.fire({
        title: 'Are you sure you want to delete the record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
            deleteData(id);
            }
        }
    })
  }

  async function deleteData(id) {

    const response = await axios.delete(`https://reactfadke.crmwindow.com/api/public/UserMaster/delete/${id}`);

    if (response.status === 200) {
        if(response.data==="Sucessfully deleted") {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record deleted Sucessfully!</b>,
            icon: 'success'
            })
            setUserMasterData((prevData) => prevData.filter((record) => record.userid !== id));
        }
  } else {
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record not deleted! Please try once again or call the developer</b>,
            icon: 'error'
        })
    }
  }

  function emptyinputs(){
    setFormData({
      userid: '',
      username: '',
      userpassword: '',
      mobile: '',
      password: '',
      usertype: '',
      accessto: '',
      centerid: '',
      user_status: '',
      operationtype: 'Add User',
    });
  }

  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');
    navigate('/loginpage');
  }

  //Below code is for making these page only accessible  to authorized users i.e., admin and manager.
  useEffect(() => {
    if(UserMasterDataGlobal.length>0){
      if (
        UserMasterDataGlobal?.[0].usertype !== "ADMIN" &&
        UserMasterDataGlobal?.[0].usertype !== "DAIRY OWNER"
      ) {
        navigate("/");
      }
    }
  }, [UserMasterDataGlobal]);

  return (
    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> User Panel</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', backgroundColor: 'lightgreen', marginTop:'-8px'}}>
          <a className="navbar-brand" href="/" >Home</a>
          {/* <a className="navbar-brand" href="/"style={{ marginLeft: "-270px" }}>Back to Order Sheet</a> */}
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
          </div>
      </nav>

      <div className='mt-4'>
      <form onSubmit={handleSubmit} method="POST" id="form1">
        <div className="mb-3 row">
          <div className='col-md-2'>
            <label htmlFor="username" className="">User name </label>          
            <input type="text" className="form-control" id="username" name='username' value={formData.username} onChange={handleInputChange} required/>
            {errors.username && <span style={{color: 'red', fontSize: '10px'}}>{errors.username}</span>}
          </div>
          <div className='col-md-2'>
            <label htmlFor="mobile">Mobile No</label>
            <input type="tel" className="form-control" id="mobile" name='mobile' value={formData.mobile} onChange={handleInputChange} required/>
            {errors.mobile && <span style={{color: 'red', fontSize: '10px'}}>{errors.mobile}</span>}
          </div>
          <div className='col-md-2'>
          <label htmlFor="password">Password</label>
            <input type="text" className="form-control" id="userpassword" name='userpassword' value={formData.userpassword} onChange={handleInputChange} required/>
            {errors.userpassword && <span style={{color: 'red', fontSize: '10px'}}>{errors.userpassword}</span>}
            {errors.userpassword1 && <span style={{color: 'red', fontSize: '10px'}}>{errors.userpassword1}</span>}
            {errors.userpassword2 && <span style={{color: 'red', fontSize: '10px'}}>{errors.userpassword2}</span>}
          </div>

          <div className='col-md-2'>
            <label htmlFor="usertype">User type</label>
            <select
            name="usertype"
            className="form-select"
            value={formData.usertype}
            onChange={handleInputChange} >
            {usertype_options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
            </select>
          </div>
          
          <div className='col-md-2'>
            <label htmlFor="accessto">Access to</label>
          
            <select
            name="accessto"
            className="form-select"
            value={formData.accessto}
            onChange={handleInputChange} >
            {accessto_options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
            </select>
          </div>
          
          <div className='col-md-2'>
            <label htmlFor="centerid">Center</label>

            <select
              className="form-select"
              id="id-centerid"
              name="centerid" // <-- should match `formData.uom1` property
              value={formData.centerid}
              onChange={handleInputChange}
            >
            {/* uom option from selected record when available */}
            {defaultcenter && (
              <option value={defaultcenter}>
                {getCenterNameById(defaultcenter)}
              </option>
            )}

            {/* dynamically fetched options */}
            {CenterMasterData.map((cp) => (
              defaultcenter !== cp.centerid ?
                <option key={cp.centerid} value={cp.centerid} >
                  {cp.centername}
                </option>
              : null
            ))}
            </select>
          </div>
        </div>      

        <div className="mb-3 row">
          <div className='col-md-2'>
            <label htmlFor="user_status">Status</label>         

            <select
            name="user_status"
            className="form-select"
            value={formData.user_status}
            onChange={handleInputChange} >
            {user_status_options.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
            </select>
          </div>
          
          <input type="hidden" className="form-control" name="userid" value={formData.userid} onChange={handleInputChange} />
          <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype}  onChange={handleInputChange} />

          <div className='col-md-2'>
            {UserMasterDataGlobal?.[0]?.usertype === "ADMIN" && formData.operationtype === "Edit User" && (
              <button type="submit" className="btn btn-primary w-100" style={{marginTop: 25}}>
                {formData.operationtype === 'Add User' ? 'Add User' : 'Edit User'}
              </button>
            )}
          </div>
        
        </div>
      </form>

      <div className="row mt-4">
      <div className="col-md-12 table-responsive-xl">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Mobile</th>
              <th>Password</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
          {records.map((u, index) => (
            <tr key={u.userid}>
              <td>{u.username}</td>
              <td>{u.mobile}</td>
              <td>{u.userpassword}</td>
              <td>{u.usertype}</td>
              <td>{u.user_status}</td>
              <td>
              <button onClick={() => getdatabyrecordarray(u)} className="btn btn-info btn-sm">Edit</button>
                {/* &nbsp;<button className="btn btn-danger btn-sm ml-1">
                Delete
                </button> */}
                &nbsp;<button onClick={(event) => confirmDelete(event, u.userid)} className="btn btn-danger btn-sm ml-1">
                Delete
                </button>
              </td>
            </tr>
           ))}
          </tbody>
        </table>
        
        <nav style={{float: 'right' }}>
          <ul className='pagination'>
              <li className='page-item'>
                <a href='#' className='page-link'
                onClick={prePage}>Prev</a>
              </li>
              
              {
                numbers.map((n, i) => (
                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                  {n === '...' ? (
                    <span className="page-link disabled">...</span>
                  ) : (
                    <a href="#" className="page-link" onClick={() => ChangeCpage(n)}>
                      {n}
                    </a>
                  )}
                </li>
              ))}

              <li className='page-item'>
                <a href='#' className='page-link'
                onClick={nextPage}>Next</a>
              </li>
          </ul>
        </nav>

      </div>
    </div>

    </div>
    </div>
  );

  function prePage() {
    if(currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function ChangeCpage(id) {
    setCurrentPage(id)
  }

  function nextPage() {
    if(currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }

};

export default UserRegistrationForm;
