// || Shree Ganeshay namha ||

// Import necessary modules from React and React Router
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your page components
import CustomerMaster from './pages/CustomerMaster';
import SalesMaster from './pages/SalesMaster';
import ProductMaster from './pages/ProductMaster';
import Navbar1 from './pages/Navbar1';
import UserRegistrationForm from './pages/UserRegistrationForm';
import LoginPage from './pages/LoginPage';
import Receipt from './pages/Receipt';
import CardCustomer from './pages/CardCustomer';
import DailyDeliverySheet from './pages/DailyDeliverySheet';
import LedgerReport from './pages/LedgerReport';
import CenterMaster from './pages/CenterMaster';
import RouteMaster from './pages/RouteMaster';
import Test2 from './pages/Test2';
import Dropdown from './pages/TestListBox';
import CustomerWiseSalesEntryCard from './pages/CustomerWiseSalesEntryCard';
import CashReceipt from './pages/reports/test3';
import OutStandingReport from './pages/reports/OutStandingReport';

// Define your main component with routes
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Navbar1 />} />
        <Route path="/SalesMaster/:transid?" element={<SalesMaster />} />
        <Route path="/ProductMaster" element={<ProductMaster />} />
        <Route path="/CustomerMaster" element={<CustomerMaster />} />
        <Route path="/registration" element={<UserRegistrationForm />} />
        <Route path="/loginpage" element={<LoginPage />} />
        <Route path="/Receipt" element={<Receipt />} />
        <Route path="/CardCustomer" element={<CardCustomer />} />
        <Route path="/DailyDeliverySheet" element={<DailyDeliverySheet />} />
        <Route path="/LedgerReport" element={<LedgerReport />} />
        <Route path="/CenterMaster" element={<CenterMaster />} />
        <Route path="/RouteMaster" element={<RouteMaster />} />
        <Route path="/Test2" element={<Test2/>}/>
        <Route path="/DropDown" element={<Dropdown/>}/>
        <Route path="/CustomerWiseSalesEntryCard" element={<CustomerWiseSalesEntryCard/>}/>
        <Route path="/test3" element={<CashReceipt />}/>
        <Route path="/OutStandingReport" element={<OutStandingReport />}/>
      </Routes>
    </Router>
  );

};

export default App;
