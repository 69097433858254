import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function CenterMaster() {

    const MySwal = withReactContent(Swal);
    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

    const navigate = useNavigate();
    // const usernameglobal='';
    
    const Redirectif = () => {
        useEffect(() => {
        if (usernameglobal == null) {
            navigate('/LoginPage');
        }
        }, [usernameglobal, navigate]);
    } 

    try {
        if (usertimestampglobal != null) {
        const parsedTimestamp = parseInt(usertimestampglobal, 10);
        const currentTime = new Date().getTime();
        const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

        if (parsedTimestamp < twelveHoursAgo) {
            localStorage.removeItem('usertimestamp');
            localStorage.removeItem('username');
            Redirectif();
        }
        }
    }
    catch {
        
    }
    
    if(usernameglobal == null){
        Redirectif();
        // return null; 
    }
    
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    useEffect(() => {
        // Fetch data from the specified URL
        axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)

            .then((response) => {
            setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [usernameglobal]);
    
    //Below code is for making these page only accessible  to authorized users i.e., admin and manager.
    useEffect(() => {
        if(UserMasterDataGlobal.length>0){
            if (
                UserMasterDataGlobal?.[0].usertype !== "ADMIN" &&
                UserMasterDataGlobal?.[0].usertype !== "DAIRY OWNER"
            ) {
                navigate("/");
            }
        }
    }, [UserMasterDataGlobal]);

    const [formData, setFormData] = useState({
        centerid: '',
        centername: '',
        operationtype: 'Save',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        var validationerrors = {};

        if(formData.get('centername').trim() !== '') {
        }else{
          validationerrors.centername = "Please enter a valid centername.";
        }   

        seterrors(validationerrors);
        
        if(Object.keys(validationerrors).length == 0) {
            // alert('no validation message');
            // return;
            var data = {
              CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
              centerid: formData.get('centerid'),
              centername: formData.get('centername'),
              operationtype: formData.get('operationtype'),
            }

            if(data.operationtype == 'Save'){
        
                const response = await axios.post('https://reactfadke.crmwindow.com/api/public/CenterMaster/insert', data);
        
                if (response.status === 200) {

                    if(!isNaN(+response.data)){
                        MySwal.fire({
                          html: <b>Record Saved Sucessfully!</b>,
                          icon: 'success'
                        })
                        const newDataItem = {
                          ...data,
                          centername: data.centername,
                          centerid: response.data,
                        };
                
                        setCenterMasterData((prevData) => {
                          return [newDataItem, ...prevData];
                        });
                        
                        emptyinputs();
                    } else if (response.data==="c_present"){
                      
                        MySwal.fire({ 
                        //   title: <strong>Record Saved Sucessfully!</strong>,
                          html: <b>  This Center name already present. can't save this record...</b>,
                          icon: 'warning'
                        })
                      
                    }
                }

            }
            else {

                const response = await axios.post('https://reactfadke.crmwindow.com/api/public/CenterMaster/update', data);
        
                if (response.status === 200) {
                
                    if(response.data.message == "Sucesss"){
                        
                        MySwal.fire({
                            //   title: <strong>Record Saved Sucessfully!</strong>,
                            html: <b>Record updated Sucessfully!</b>,
                            icon: 'success'
                        })
                        
                        setCenterMasterData((prevData) => {
                          const updatedData = [...prevData];
                          const index = updatedData.findIndex((record) => record.centerid == data.centerid);
        
                          if (index !== -1) {
                            updatedData[index] = { ...updatedData[index], ...data };
                          }           
                            
                          return updatedData;
                        });
                        // console.log(CustomerMasterData);
                        emptyinputs();
                    } 

                }

            }

        }

    }

    function navigatetologin() {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        navigate('/loginpage');
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [errors, seterrors] = useState({});
    const [CenterMasterData, setCenterMasterData] = useState([]);
    //2) using axios
    useEffect(() => {
    // Fetch data from the specified URL
      if (UserMasterDataGlobal.length > 0) {
        axios.get(`https://reactfadke.crmwindow.com/api/public/CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)
  
        .then((response) => {
          // Access the response data directly using response.data
          setCenterMasterData(response.data.CenterMasterData);
          if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
            setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
            setFormData({
              ...formData,
              centerid: UserMasterDataGlobal?.[0]?.centerid,
            })
          }
        })
        .catch((error) => console.error('Error fetching data:', error));
      }
    }, [UserMasterDataGlobal]);

    function confirmDelete(event, id) {
        event.preventDefault();
  
        MySwal.fire({
            title: 'Are you sure you want to delete the record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                // window.location.href = $(this).attr('href');
                deleteData(id);
                }
            }
        })
    }
  
    async function deleteData(id) {

      const response = await axios.delete(`https://reactfadke.crmwindow.com/api/public/CenterMaster/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);

      if (response.status === 200) {
        // The record was deleted successfully.
        // console.log('Record deleted successfully!');
          if(response.data==="Sucessfully deleted") {
              MySwal.fire({
              //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Record deleted Sucessfully!</b>,
              icon: 'success'
              })
              setCenterMasterData((prevData) => prevData.filter((record) => record.centerid !== id));
          } else if(response.data == "found") {
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>This center has transactions; so can't delete this account</b>,
                icon: 'warning'
            })        
          } 
    } else {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Record not deleted! Please try once again or call the developer</b>,
              icon: 'error'
          })
      }
    }

    const [defaultcenter, setdefaultcenter] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = CenterMasterData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(CenterMasterData.length / recordsPerPage);
    //const numbers = [...Array(npage + 1).keys()].slice(1);
    const getVisiblePageNumbers = (currentPage, totalPages) => {
      // Handle cases with 5 or fewer pages
      if (totalPages <= 5) {
        return [...Array(totalPages + 1).keys()].slice(1);
      }
    
      // Handle cases with more than 5 pages
      const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
      let gap = false;
      const finalNumbers = [];
    
      for (let i = 1; i <= totalPages; i++) {
        if (visiblePages.includes(i)) {
          finalNumbers.push(i);
          gap = false;
        } else if (!gap) {
          finalNumbers.push('...');
          gap = true;
        }
      }
    
      return finalNumbers;
    };
    
    const numbers = getVisiblePageNumbers(currentPage, npage);
    
    function getdatabyrecordarray(record) {
        
        setFormData({
            centerid: record.centerid,
            centername: record.centername,
            operationtype: 'Edit',
        });
        // following code is to go to top of the page after clicking on edit button.
        window.scrollTo(0, 0)
    }

    function emptyinputs(){
        setFormData({
            centerid: '',
            centername: '',
            operationtype: 'Save',
        });
    }

    return (
        
    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Center Master</h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', backgroundColor: 'lightgreen', marginTop:'-8px'}}>
          <a className="navbar-brand" href="/" >Home</a>
          {/* <a className="navbar-brand" href="/"style={{ marginLeft: "-270px" }}>Back to Order Sheet</a> */}
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
          </div>
      </nav>

    <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        <div className="col-md-6 offset-md-3">
            <form onSubmit={handleSubmit} method="POST" id="form1">
                <div className="row mb-3">
                    <div className="col-md-3">
                        <input type="hidden" className="form-control" name="centerid" value={formData.centerid}  onChange={handleInputChange}
                        placeholder='Type here' required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="centername" className="form-label">Center Name</label>
                        <input type="text" className="form-control" name="centername" value={formData.centername}  onChange={handleInputChange}
                        placeholder='Type here' required />
                        {errors.centername && <span style={{color: 'red', fontSize: '10px'}}>{errors.centername}</span>}
                    </div>

                    <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype}  onChange={handleInputChange}
                    required />

                    <div className="col-md-4">
                        <button type="submit" className="btn btn-primary" style={{marginTop: 29, height: 42}}>{formData.operationtype == 'Save' ? 'Save' : 'Edit'}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <div className="row mt-4">
    <div className="col-md-12 table-responsive-xl">
        <table className="table table-bordered">
            <thead>
                <tr>
                <th>Center No</th>
                <th>Center Name</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {records.map((c, index) => (
                <tr key={c.centerid}>
                <td>{c.centerid}</td>
                <td>{c.centername}</td>
                <td>
                    <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
                    {/* <Link to={generatePath(routes.customerDetails, { customerid1: c.customer_mp_id })}  className="btn btn-success btn-sm">edit</Link> */}
                    &nbsp;<button onClick={(event) => confirmDelete(event, c.centerid)} className="btn btn-danger btn-sm ml-1">
                    Delete
                    </button>
                </td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
    </div>
    </div>

    );

}

export default CenterMaster;
