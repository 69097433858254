import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

function Navbar1() {

  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  
  // const usernameglobal='';
  const Redirectif = () => {
    useEffect(() => {
      navigate('/LoginPage');
    }, []);
  }

  const Getusermasterglobaldata = () => {
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch data from the specified URL
          const response = await axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username=' + usernameglobal);
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [/* dependencies, if any */]);
  };  
  
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

  try {
    if (usertimestampglobal != null) {
      const parsedTimestamp = parseInt(usertimestampglobal, 10);
      const currentTime = new Date().getTime();
      const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

      if (parsedTimestamp < twelveHoursAgo) {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        // Redirectif();
      }
    }
  }
  catch {

  }

  if(usernameglobal == null){
    Redirectif();
  }
  else{
    //2) using axios
    Getusermasterglobaldata();
  }
  // console.log(UserMasterDataGlobal[0].usertype);
  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');
    navigate('/loginpage');
  }

  return (

    <div className="container-fluid" style={{ color: '#4229cb', backgroundColor: 'rgb(255 255 255)' }}>
      <h2 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px',marginBottom:'0px', cursor: 'default'}} className="text-center">
        मिल्कप्रो - MSDS
      </h2>
      {/* <h4 style={{ color: 'white', backgroundColor: '#4229cb', marginTop:'0px', padding: '5px' }} className="text-center">
        (Milk Sales & Distribution System)
      </h4> */}
      <h4 style={{ fontSize:'20px', color: 'white', backgroundColor: '#4229cb', marginTop:'0px', padding: '5px', cursor: 'default' }} className="text-center">
        Licensed to : Phadake Dairy, Pune
      </h4>
      
      <Navbar style={{ marginTop: -8, marginBottom: 18, color: 'white'}} expand="lg" bg="dark" variant="dark" >
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="mr-auto">
            <NavDropdown title="Master" id="basic-nav-dropdown">
              <NavDropdown.Item href="/CustomerMaster">Customer Master</NavDropdown.Item>
              <NavDropdown.Item href="/ProductMaster">Product Master</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Transaction" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#">Purchase</NavDropdown.Item> */}
              <NavDropdown.Item href="/DailyDeliverySheet">Daily Order Sheet</NavDropdown.Item>
              <NavDropdown.Item href="/Test2">Sales</NavDropdown.Item>
              <NavDropdown.Item href="/CustomerWiseSalesEntryCard">Customer Card Alteration</NavDropdown.Item>
              {UserMasterDataGlobal?.[0]?.usertype === "ADMIN" && (
                <NavDropdown.Item href="/Receipt">Receipt</NavDropdown.Item>
              )}     
            </NavDropdown>
            {UserMasterDataGlobal?.[0]?.usertype === "ADMIN" && (
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdown.Item href="/registration">Registration</NavDropdown.Item>
              <NavDropdown.Item href="/CenterMaster">Center Master</NavDropdown.Item>
              <NavDropdown.Item href="/RouteMaster">Line Master</NavDropdown.Item>
            </NavDropdown>
            )}      
            <NavDropdown title="Reports" id="basic-nav-dropdown">
              <NavDropdown.Item href="/OutStandingReport">OutStanding List</NavDropdown.Item>
            </NavDropdown>      
          </Nav>
        </Navbar.Collapse>
        <Nav.Link style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</Nav.Link>
        <Nav.Link onClick={navigatetologin} style={{ marginLeft: 15, marginRight:5, cursor: 'pointer' }}>
          Log out
        </Nav.Link>
      </Navbar>
    </div>
    
    );

}

export default Navbar1;
