// import React, { useState } from 'react';
// import Select from 'react-select';
// // import 'react-select/style.css'; // Import CSS

// const options = [
//   { value: 'option1', label: 'Option 1' },
//   { value: 'option2', label: 'Option 2' },
//   { value: 'option3', label: 'Option 3' },
//   { value: 'option4', label: 'Option 4' },
//   { value: 'option5', label: 'Option 5' },
//   { value: 'option6', label: 'Option 6' }
// ];

// const Dropdown = () => {
//   const [selectedOption, setSelectedOption] = useState(options[3]); // Initially select 4th option

//   const handleChange = (selected) => {
//     setSelectedOption(selected);
//   };

//   return (
//     <Select
//       value={selectedOption}
//       onChange={handleChange}
//       options={options}
//     />
//   );
// };

// export default Dropdown;


import React, { useState } from 'react';
import Select from 'react-select';
// import 'react-select/style.css';

const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
  { value: 'option4', label: 'Option 4' },
  { value: 'option5', label: 'Option 5' },
  { value: 'option6', label: 'Option 6' },
  { value: 'option7', label: 'Option 7' },
];

const Dropdown = () => {
  const [selectedOption, setSelectedOption] = useState(options[3]); // Initially 4th option
  const [userInput, setUserInput] = useState(''); // Add state for user input

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleButtonClick = () => {
    const parsedInput = parseInt(userInput);
    if (parsedInput >= 1 && parsedInput <= options.length) {
      setSelectedOption(options[parsedInput - 1]); // Select option based on index
    } else {
      // Handle invalid input (e.g., display error message)
    }
  };

  return (
    <div>
      <h1>My Dropdown</h1>
      <Select value={selectedOption} onChange={handleChange} options={options} />
      <br />
      <input type="text" value={userInput} onChange={handleInputChange} placeholder="Enter a number (1-6)" />
      <button onClick={handleButtonClick}>OK</button>
    </div>
  );
};

export default Dropdown;