import React, { useEffect, useState, useRef  } from 'react';
import { useParams, useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, json, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function SalesMaster() {
  
  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

  const navigate = useNavigate();

  const Redirectif = () => {
    useEffect(() => {
      if (usernameglobal == null) {
        navigate('/LoginPage');
      }
    }, [usernameglobal, navigate]);
  }

  try {
    if (usertimestampglobal != null) {
      const parsedTimestamp = parseInt(usertimestampglobal, 10);
      const currentTime = new Date().getTime();
      const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

      if (parsedTimestamp < twelveHoursAgo) {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        Redirectif();
      }
    }
  }
  catch {
    
  }

  if(usernameglobal == null){
    Redirectif();
  }

  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);

  useEffect(() => {
    // Fetch data from the specified URL
    axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)

        .then((response) => {
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        })
        .catch((error) => console.error('Error fetching data:', error));
  }, [usernameglobal]);


  const MySwal = withReactContent(Swal)
  const [defaultcenter, setdefaultcenter] = useState();
  const [defaultroutename, setdefaultroutename] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [customer_name_data,  setcustomer_name_data] =  useState([]);
  const [errors,  seterrors] =  useState({});
  const customer_centerwiseidRef = useRef(null);
  const qtyInputRefs = useRef([]);
  const [defaultbank, setdefaultbank] = useState();

  const [CenterMasterData, setCenterMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(`https://reactfadke.crmwindow.com/api/public/CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)

      .then((response) => {
        setCenterMasterData(response.data.CenterMasterData);
        // console.log(response.data.CenterMasterData);
        //skipped following line because at centerid we are directly using 
        //defaultcenter
        // setFormData({
        //   ...formData,
        //   centerid: response.data.CenterMasterData?.[0]?.centerid,
        // })
        
        if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
          setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
          setFormData({
            ...formData,
            centerid: UserMasterDataGlobal?.[0]?.centerid,
          })
        }   
               
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const [formData, setFormData] = useState({
    centerno: '',
    centerid: '',
    routecode: '',
    routename: '',
    customer_mp_id: '',
    customer_centerwiseid: '',
    customer_name: '',
    customer_place: '',
    customer_mobile: '',
    prevbalrs: '',
    transdate: '',
    totpay: '',
    cash_recd: '',
    bank_recd: '',
    balance: '',
    billamt: '',
    qty: '',
    amt: '',
    rate: '',
    opbal: '',
    operationtype: 'Save',
    transid: '',
  });

  useEffect(() => {
    // Update the date in the formData when the component mounts
    setFormData((prevData) => ({
      ...prevData,
      transdate : getCurrentDate(),
    }));
  }, []); 

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      customer_centerwiseid:'',
    })
    setcustomer_name_data([]);

    const { name, value } = e.target;
    // console.log('s');
    // return;
    
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    
    if (name === 'centerid') {
      // const [customer_centerwiseid, customerName] = value.split('-');
      updatedFormData.centerno = value;
      getroutemasterdata(value);      
      getcustomerdata(defaultroutename, value);
    }

    if (name === 'routename') {
      // const [customer_centerwiseid, customerName] = value.split('-');
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid : '',
      })); 
      setcustomer_name_data([]);
      setdefaultroutename(value);
    }

    if (name === 'customer_name') {
      const [customer_centerwiseid, customerName] = value.split('-');
      updatedFormData.customer_centerwiseid = customer_centerwiseid;

      customer_nameonblur(customer_centerwiseid);
    }

    async function customer_nameonblur(customer_centerwiseid) {

      var centeridtopass='';
      if(UserMasterDataGlobal?.[0]?.usertype  !== "ADMIN" &&  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"){
        centeridtopass = UserMasterDataGlobal?.[0]?.centerid;
      }
      else{
        centeridtopass = defaultcenter;
      }
    
      try {
        const apireq = `https://reactfadke.crmwindow.com/api/public/CustomerMaster/getrecordbybycustomer_centerwiseid?customer_centerwiseid=${customer_centerwiseid}&CompanyId=${UserMasterDataGlobal?.[0]?.CompanyId}&centerid=${centeridtopass}&routename=${formData.routecode}`;
  
        const response = await axios.get(apireq);
        // Access the response data directly using response.data
        if(response.data.CustomerMasterData.length > 0){
          setcustomer_name_data([]);
          setcustomer_name_data(response.data.CustomerMasterData);
          // console.log(response.data.CustomerMasterData);
          setFormData({
            ...formData,
            prevbalrs: response.data.CustomerMasterData[0].clbal,
            routecode: response.data.CustomerMasterData[0].routecode,
            routename: response.data.CustomerMasterData[0].routename,
            customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
            customer_place: response.data.CustomerMasterData[0].customer_place,
            customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
            opbal: response.data.CustomerMasterData[0].opbal,
            centerid: response.data.CustomerMasterData[0].centerid,
            // newly added code on 30/01/2024
            customer_centerwiseid: customer_centerwiseid,
          });
          
          setRouteMasterData(
            RouteMasterData.sort((a, b) => (a.routename === response.data.CustomerMasterData[0].routename ? -1 : 1))
          );
    
        } else {
          if(formData.customer_centerwiseid !== '') {
            alert("Apologies, no customer found; they may be located at a different center...");
            setFormData({
              ...formData,
              customer_centerwiseid: '',
            });
            setcustomer_name_data([]);
          }        
        }
      } catch(error) {
        console.error('Error fetching data:', error);
      }
    };
    
    if (name === 'routename') {
      const [routecode, routename] = value.split('-');
      updatedFormData.routecode = routecode;
    }

    setFormData(updatedFormData);

  }; 

  const [UnitMasterData, setUnitMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
  axios.get('https://reactfadke.crmwindow.com/api/public/UnitMaster')

    .then((response) => {
        // Access the response data directly using response.data
        setUnitMasterData(response.data.UnitMasterData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const [productData, setProductData] = useState([]);
  const [ProductMasterData, setProductMasterData] = useState([]);
  //2) using axios

  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0 && ProductMasterData.length == 0 ) {
      axios.get(`https://reactfadke.crmwindow.com/api/public/ProductMaster?orderby=ASC&CompanyId=`+UserMasterDataGlobal?.[0]?.CompanyId)
      .then((response) => {
        // Access the response data directly using response.data
        setProductMasterData(response.data.ProductMasterData);
        
        const initialProductData = response.data.ProductMasterData.map((p) => ({
          ...p,
          qty: 0,
          amt: 0,
        }));

        setProductData(initialProductData);
      })

      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const [RouteMasterData, setRouteMasterData] = useState([]);
  //2) using axios
  // useEffect(() => {
  // // Fetch data from the specified URL
  //   if (UserMasterDataGlobal.length > 0) {
  //     axios.get('https://reactfadke.crmwindow.com/api/public/RouteMaster?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)

  //     .then((response) => {
  //         // Access the response data directly using response.data
  //         setRouteMasterData(response.data.RouteMasterData);
  //         setFormData({
  //           ...formData,
  //           routecode: response.data.RouteMasterData?.[0]?.routecode,
  //         })

  //       })
  //       .catch((error) => console.error('Error fetching data:', error));
  //   }
  // }, [UserMasterDataGlobal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const [customer_centerwiseid, customerName] = formData.get("customer_name").split('-');
    const [routecode, routename] = formData.get("routename").split('-');
    var cramtsummation;
    var transaction_id;
    var validationerrors = {};

    var cowqty, cowrate, cowamt, buffqty, buffrate, buffamt = 0;
    ProductMasterData.forEach((p, index) => {

      if(formData.get(`product_category[${index}]`) == "Cow Milk") {
        cowqty = formData.get(`qty[${index}]`);
        cowrate = formData.get(`rate[${index}]`);
        cowamt = formData.get(`amt[${index}]`);
      }
      
      if(formData.get(`product_category[${index}]`) == "Buff Milk") {
        buffqty = formData.get(`qty[${index}]`);
        buffrate = formData.get(`rate[${index}]`);
        buffamt = formData.get(`amt[${index}]`);        
      }
    });

    cramtsummation = Number(formData.get("cash_recd")) + Number(formData.get("bank_recd"))

    if(formData.get('customer_centerwiseid').trim() !== '') {
    }
    else{
      validationerrors.customer_centerwiseid = "Please enter a valid value for customer id.";
    }

    seterrors(validationerrors);

    var calc1 =
      Number(totalAmount) +
      Number(formData.get('prevbalrs'));

    var calc2 = Number(formData.get('cash_recd')) +
                Number(formData.get('bank_recd'));
    var calc3 = calc1 - calc2; 
    
    const yourString = formData.get('routename');
    const parts = yourString.split('-');
    const getroutename = parts.slice(1).join('-');
    const getroutecode = parts[0];

    var data = {
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      centerid: formData.get('centerid'),
      userid: UserMasterDataGlobal?.[0]?.userid,
      trantype: 'SALE',
      transdate: formData.get('transdate'),
      customer_mp_id: formData.get('customer_mp_id'),
      customer_centerwiseid: customer_centerwiseid,
      customer_name: customerName,
      customer_place: formData.get('customer_place'),
      customer_mobile: formData.get('customer_mobile'),
      routename: getroutename,
      routecode: getroutecode,
      cowqty: cowqty,
      cowrate: cowrate,
      cowamt: cowamt,
      buffqty: buffqty,
      buffrate: buffrate,
      buffamt: buffamt,
      productsale_amt: Number(formData.get('billamt')) - Number(cowamt) - Number(buffamt),
      bankname: formData.get('bankname'),
      dramt: formData.get('billamt'),
      opbal: formData.get('opbal'),
      cramt: cramtsummation,
      balance: calc3,
      cash_recd: formData.get('cash_recd'), 
      bank_recd: formData.get('bank_recd'),
      transid: formData.get('transid'),
      operationtype: formData.get('operationtype'),
    };

    var transaction_id = '';
    if(Object.keys(validationerrors).length == 0) {

      if(data.operationtype == "Save") {
        const response = await axios.post('https://reactfadke.crmwindow.com/api/public/OtherController/savesales', data);

        if (response.status === 200) {
          // console.log(response.data);
          transaction_id = response.data;
        
          // if(response.data==="Sucesss"){
          //     MySwal.fire({
          //       html: <b>Record Saved Sucessfully!</b>,
          //       icon: 'success'
          //     })
          // } 
        }

        const saveProductData = async (productData) => {
          try {
            const response = await axios.post('https://reactfadke.crmwindow.com/api/public/OtherController/salesdetails', productData);
        
            if (response.status === 200) {
              //console.log(response.data);
            }
          } catch (error) {
            console.error('Error saving product data:', error);
          }
        };
        
        ProductMasterData.forEach(async (product, index) => {
          const productData = {
            transaction_id: transaction_id,
            transdate: formData.get('transdate'),
            CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
            productid: formData.get(`productid[${index}]`),
            product_name: formData.get(`product_name[${index}]`),
            rate: formData.get(`rate[${index}]`),
            qty: formData.get(`qty[${index}]`),
            amt: formData.get(`amt[${index}]`),
          };
        
          if(productData.amt != 0){
            await saveProductData(productData);
          }
        });

        MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record Saved Sucessfully!</b>,
          icon: 'success'
        })

        var data = {
          centerno: data.centerid,
          routecode: data.routecode,
          bookdate: data.transdate,
          CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
          userid: UserMasterDataGlobal?.[0]?.userid,
        };    

        temporaryvalues_table(data)

        emptyinputs();
        // localStorage.removeItem('usertimestamp');
      }
      else {

        const response = await axios.post('https://reactfadke.crmwindow.com/api/public/OtherController/editsales', data);

        if (response.status === 200) {

          // if(response.data==="Sucesss"){
          //     MySwal.fire({
          //       html: <b>Record Saved Sucessfully!</b>,
          //       icon: 'success'
          //     })
          // } 
        }

        const saveProductData = async (productData) => {
          try {
            const response = await axios.post('https://reactfadke.crmwindow.com/api/public/OtherController/editsalesdetails', productData);
        
            if (response.status === 200) {
              //console.log(response.data);
            }
          } catch (error) {
            console.error('Error saving product data:', error);
          }
        };
        
        ProductMasterData.forEach(async (product, index) => {
          const productData = {
            transid: data.transid,
            transdate: formData.get('transdate'),
            CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
            productid: formData.get(`productid[${index}]`),
            product_name: formData.get(`product_name[${index}]`),
            rate: formData.get(`rate[${index}]`),
            qty: formData.get(`qty[${index}]`),
            amt: formData.get(`amt[${index}]`),
          };
        
          if(productData.amt != 0){
            await saveProductData(productData);
          }

        });

        MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record updated Sucessfully!</b>,
          icon: 'success'
        })
        navigate('/DailyDeliverySheet');
      }
    }
    else{
      console.log("validation errors found.")
    }
  } 

  async function temporaryvalues_table(data) {

    var validationerrors = {};

    seterrors(validationerrors);

    if(Object.keys(validationerrors).length == 0) {
      // alert('no validation message');
      // return;   

      try {
      
        var response  = "";
        response = await axios.post('https://reactfadke.crmwindow.com/api/public/DailyDeliverySheet/updateinsert_temporaryvalues_table', data);

        if (response.status === 200) {

        }
        
      } catch {

      } 

    }  
  }

  const handleQtyChange = (index, event) => {
    
    if(formData.operationtype == "Save") {
      setProductData((prevProductData) => {
        const updatedProductData = [...prevProductData];
        const qty = parseInt(event.target.value, 10) || 0;
        updatedProductData[index].qty = qty;
        updatedProductData[index].amt = qty * updatedProductData[index].mrp;
    
        const newTotalAmount = updatedProductData.reduce((sum, product) => sum + product.amt, 0);
        setTotalAmount(newTotalAmount);
  
        return updatedProductData;
      });
    }
    else {
      setProductMasterDataToEdit((prevProductData) => {
        const updatedProductData = [...prevProductData];
        const qty = parseInt(event.target.value, 10) || 0;
        updatedProductData[index].qty = qty;
        updatedProductData[index].amt = qty * updatedProductData[index].mrp;
    
        const newTotalAmount = updatedProductData.reduce((sum, product) => sum + product.amt, 0);
        setTotalAmount(newTotalAmount);
  
        return updatedProductData;
      });
    }
  };

  const routecodeonblur = async (e) => {
    e.preventDefault();
    var { name, value } = e.target;

    if (value.includes('-')) {
      // Split the value based on '-'
      const [routecode, routename] = value.split('-');
      value = routecode;
    }

    if(value == ''){
      return;
    }
    setFormData({
      ...formData,
      customer_centerwiseid:'',
    })
    setcustomer_name_data([]);
    // setcustomer_name_data(response.data.customer_name_data);

    var axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebyroutecode?routecode='+value
    +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+formData.centerid;
    console.log(axiosreqtoapi);
    const response = await axios.get(axiosreqtoapi)
    
    .then((response) => {
      // Access the response data directly using response.data
      setcustomer_name_data([]);
      setcustomer_name_data(response.data.customer_name_data);
      console.log(response.data.customer_name_data);
      if (response.data.customer_name_data.customer_centerwiseid!==null) {
        try {
          const routename1 = response.data.customer_name_data[0].routename;
          setFormData({
            ...formData,
            routename: routename1,
            customer_centerwiseid: response.data.customer_name_data[0].customer_centerwiseid,
          });        
          setRouteMasterData(
            RouteMasterData.sort((a, b) => (a.routename === routename1 ? -1 : 1))
          );
        }
      
        catch {
          if(formData.routecode != '') {
            setFormData({
              ...formData,
              routecode: '',
            });
            // alert("Choose proper route...")
          }
        }
        } else {
          alert('No Data Found');
          setcustomer_name_data([]);
          // setcustomer_name_data(response.data.customer_name_data);
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  };

  const customer_centerwiseidonblur = async (e) => {
    e.preventDefault();
    var { name, value } = e.target;
    var data = {
      routecode: value,
    }

    if (value.includes('-')) {
      // Split the value based on '-'
      const [customer_centerwiseid, customer_name] = value.split('-');
      value = customer_centerwiseid;
    }
    
    if(value == ''){
      return;
    }

    var centeridtopass='';
    if(UserMasterDataGlobal?.[0]?.usertype  !== "ADMIN" &&  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"){
      centeridtopass = UserMasterDataGlobal?.[0]?.centerid;
    }
    else{
      centeridtopass = defaultcenter;
    }

    const axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/CustomerMaster/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+value+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
    +'&centerid='+centeridtopass+'&routename='+defaultroutename;

    const response = await axios.get(axiosreqtoapi)
    
    .then((response) => {
      // Access the response data directly using response.data
      if(response.data.CustomerMasterData.length > 0){
        setcustomer_name_data([]);
        setcustomer_name_data(response.data.CustomerMasterData);

        setFormData({
          ...formData,
          prevbalrs: response.data.CustomerMasterData[0].clbal,
          routecode: response.data.CustomerMasterData[0].routecode,
          routename: response.data.CustomerMasterData[0].routename,
          customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
          customer_place: response.data.CustomerMasterData[0].customer_place,
          customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
          opbal: response.data.CustomerMasterData[0].opbal,
          centerid: response.data.CustomerMasterData[0].centerid,
          // newly added code on 30/01/2024
          customer_centerwiseid: value,
        });
        
        setRouteMasterData(
          RouteMasterData.sort((a, b) => (a.routename === response.data.CustomerMasterData[0].routename ? -1 : 1))
        );

      } else {
        if(formData.customer_centerwiseid != '') {
          alert("Apologies, no customer found; they may be located at a different center...");
          setFormData({
            ...formData,
            customer_centerwiseid: '',
          });
          setcustomer_name_data([]);
        }        
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function calculate_balance() {
    var calc1 =
      Number(totalAmount) +
      Number(formData.prevbalrs);

    var calc2 = Number(formData.cash_recd) +
                Number(formData.bank_recd);
    var calc3 = calc1 - calc2;

    setFormData({
      ...formData,
      balance: calc3,
    });
  }
  
  const [BankAccountData, setBankAccountData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if(UserMasterDataGlobal.length>0){
      axios.get('https://reactfadke.crmwindow.com/api/public/OtherController?CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId)

      .then((response) => {
          // Access the response data directly using response.data
          setBankAccountData(response.data.bank_account_data);
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  function emptyinputs(){

    setFormData({
      centerid: '',
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      routecode: '',
      routename: '',
      customer_mp_id: '',
      customer_centerwiseid: '',
      customer_name: '',
      customer_place: '',
      customer_mobile: '',
      prevbalrs: '',
      totpay: '',
      cash_recd: '',
      bank_recd: '',
      balance: '',
      billamt: '',
      opbal: '',
      operationtype: 'Save'
    });

    qtyInputRefs.current.forEach((inputRef) => {
      inputRef.value = ''; // or set it to 0 if you prefer
    });

    setTotalAmount(0);

    setProductData((prevProductData) => {
      return prevProductData.map((product) => ({
        ...product,
        qty: 0,
        amt: 0,
      }));
    });
    customer_centerwiseidRef.current.focus();
  }
  
  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');
    navigate('/loginpage');
  }

  function navigatetodailydelivery() {
    navigate('/DailyDeliverySheet')
  }

  const [ProductMasterDataToEdit, setProductMasterDataToEdit] = useState([]);

  const centernoonblur = (e) => {
    e.preventDefault();
    var { name, value } = e.target;

    setdefaultcenter(value);
    getroutemasterdata(value);
  }

  const centeridonblur = (e) => {
    e.preventDefault();
    var { name, value } = e.target;
    if (name === 'centerid') {
      // const [customer_centerwiseid, customerName] = value.split('-');
      getroutemasterdata(value);      
      getcustomerdata(defaultroutename, value);
    }
  }

  // const [temporaryvalues_table_data, settemporaryvalues_table_data] = useState([]);
  //asdf
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0) {
      
      const userIdapireq = UserMasterDataGlobal[0]?.userid;
      const companyIdapireq = UserMasterDataGlobal[0]?.CompanyId;
      
      const apirequest = `https://reactfadke.crmwindow.com/api/public/DailyDeliverySheet/gettemporaryvalues_tabledata?CompanyId=${companyIdapireq}&userid=${userIdapireq}`;

      axios.get(apirequest)
        .then((response) => {
          try {
            // settemporaryvalues_table_data(response.data);
            setdefaultroutename(response.data[0].text3);
            setdefaultcenter(response.data[0].text1);

            setFormData({
              ...formData,
              routecode: response.data[0].text3,
              centerno: response.data[0].text1,
              bookdate: response.data[0].text5,
            });
            // console.log('s');
            getcustomerdata(response.data[0].text3, response.data[0].text1);
            
          }
          catch {

          }          

        })

        .catch((error) => console.error('Error fetching route data:', error));
    }
  }, [UserMasterDataGlobal]);

  //below function is to get the customerdata  based on routename and center no. and companyid
  async function getcustomerdata(routecode, centerid) {

    var axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebyroutecode?routecode='+routecode
    +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid;
    
    const response = axios.get(axiosreqtoapi)
    
    .then((response) => {
      setcustomer_name_data([]);
      setcustomer_name_data(response.data.customer_name_data);

      try {
        setFormData((prevData) => ({
          ...prevData,
          customer_centerwiseid : '1',
        })); 
        // customer_centerwiseidRef.current.focus();
      }
      catch {
        if(formData.routecode != '') {
          // setFormData({
          //   ...formData,
          //   routecode: '',
          // });
          // alert("Choose proper route...")
        }
      }
      // customer_centerwiseidRef.current.focus();   
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });

  }

  const getCenterNameById = (centerid) => {
    const center = CenterMasterData.find(center => center.centerid === centerid);
    return center ? center.centername : '';
  };

  async function getroutemasterdata(centerid, rr=null) {
    // console.log(rr);
    var data1 = {
      centerid : centerid,  
      CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    // console.log(data1);
    var response1 = axios.get(`https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
      setdefaultroutename(response1.data[0].routename);
      setRouteMasterData(response1.data);
    })
  }

  const [defaultcustomername, setdefaultcustomername] = useState();

  return (

    <div className="container" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px' }} className="text-center"> Sales </h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid" style={{color: 'black', backgroundColor: 'lightgreen', marginTop:'-8px'}}>
          <a className="navbar-brand" href="/" >Home</a>
          {/* <a className="navbar-brand" href="/"style={{ marginLeft: "-270px" }}>Back to Order Sheet</a> */}
          <ul className="navbar-nav">
              <li className="nav-item">
                <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
        </div>
      </nav>
    
    <form onSubmit={handleSubmit} method="POST" id="form1" >
    <div className="row" styles="background-color:lightgreen;">
      <div className="col-md-6 offset-md-3">
        {/* <form onSubmit={handleSubmit} method="POST" id="form1"> */}
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="id-transdate" className="form-label">Date</label>
              <input type="date" className="form-control" name="transdate" id="id-transdate"  value={formData.transdate}  onChange={handleInputChange} />
            </div>
          </div>
          <div className='row mb-3'>
            <div className="col-md-4">
              <label htmlFor="centerno" className="form-label">Center No</label>
              <input type="number" className="form-control" name="centerno" value={formData.centerno}  onChange={handleInputChange} onBlur={centernoonblur} />
            </div>
            <div className="col-md-8">
              <label htmlFor="centerid" className="form-label">Center</label>
              <select
                  className="form-select"
                  id="id-centerid"
                  name="centerid" // <-- should match `formData.uom1` property
                  // value={formData.centerid}
                  onChange={handleInputChange}
                  onBlur={centeridonblur}
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {getCenterNameById(cp.centerid)}
                    </option>
                  : null
                ))}
                </select>              
            </div>
          </div>
          
          <div className="row mb-3">
            {/* <div className="col-md-4"> */}
              {/* <label htmlFor="id-routecode" className="form-label">Route Code</label> */}
              <input type="text" className="form-control" name="routecode" id="id-routecode"  value={defaultroutename}  onChange={handleInputChange} onBlur={routecodeonblur} 
              placeholder='Type here' required />
            {/* </div> */}
            <div className="col-md-4">
              <label htmlFor="id-routename" className="form-label">Line No</label>
              
              <select
              name="routename"
              className="form-select"
              id="id-routename"
              // value={formData.routename}
              onChange={handleInputChange}
              onBlur={routecodeonblur}
              >
              
              {defaultroutename && (
                <option key={defaultroutename} value={defaultroutename}>
                  {defaultroutename}
                </option>
              )}

              {RouteMasterData.map((r) => (
                  defaultroutename !== r.routename ?
                    <option key={r.routename} value={r.routename} >
                      {r.routename}
                    </option>
                  : null
                ))}

              {/* {RouteMasterData.map((option) => (
                <option key={option.routecode} value={`${option.routename}`} onBlur={routecodeonblur} >{option.routename}</option>
              ))} */}
              </select>

            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="id-purchase_rate" className="form-label">Customer No</label>
              <input type="number" className="form-control" name="customer_centerwiseid" id="id-customer_centerwiseid" ref={customer_centerwiseidRef} value={formData.customer_centerwiseid} onChange={handleInputChange} onBlur={customer_centerwiseidonblur} placeholder='Type here' required />
              {errors.customer_centerwiseid && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_centerwiseid}</span>}
              <input type="hidden" className="form-control" name="customer_mp_id" value={formData.customer_mp_id} onChange={handleInputChange} />              
              <input type="hidden" className="form-control" name="customer_place" value={formData.customer_place} onChange={handleInputChange} />              
              <input type="hidden" className="form-control" name="customer_mobile" value={formData.customer_mobile} onChange={handleInputChange} />              
              <input type="hidden" className="form-control" name="opbal" value={formData.opbal} onChange={handleInputChange} />      
              <input type="hidden" className="form-control" name="centerid" value={formData.centerid} onChange={handleInputChange} />      
                            
            </div>
            <div className="col-md-8">
              <label htmlFor="id-customername" className="form-label">Customer Name</label>
              {/* <input type="text" className="form-control" name="customername" id="id-customername" defaultValue={formData.customername} onChange={handleInputChange} required/> */}
              <select
              name="customer_name"
              className="form-select"
              id="id-customer_name"
              // value={formData.customer_name}
              onChange={handleInputChange} 
              >
              {Array.isArray(customer_name_data) && customer_name_data.map((option) => (
                <option key={option.customer_centerwiseid}  value={`${option.customer_centerwiseid}-${option.customer_name}`} >{option.customer_name}</option>
              ))}
              </select>

            </div>     
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="id-prevbal" className="form-label">Prev bal rs</label>
              <input type="text" className="form-control" name="prevbalrs" id="id-prevbalrs" value={formData.prevbalrs} onChange={handleInputChange} readOnly/>
            </div>            
          </div>
        {/* </form> */}
      </div>
    </div>
   
    <div className="row mt-4">
      <div className="table-container" style={{ height: '200px', overflow: 'auto' }}>
        <table className="table table-bordered">
          <tbody>
            {formData.operationtype === 'Save' ? (
              <>
                {ProductMasterData.map((p, index) => (

                <tr style={{marginTop:'50px',borderBottom:'20px', borderWidth:'10px'}} key={p.productid}>
                  <td>
                    {p.productid}
                    <input type='hidden' name={`productid[${index}]`} value={p.productid} onChange={handleInputChange}></input>
                  </td>
                  {/* <td>{p.product_name}</td> */}
                  <td>
                    <label htmlFor="product_name">{p.product_name}</label>
                    <input type='hidden' name={`product_name[${index}]`} value={p.product_name} onChange={handleInputChange}></input>
                    <input type='hidden' name={`product_category[${index}]`} value={p.product_category} onChange={handleInputChange}></input>
                    <br/>
                    {p.product_img && (
                      <img
                        src={p.product_img}
                        alt={`Sample Image for ${p.product_name}`}
                        style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: '10px' }}
                      />
                    )}              
                  </td>
                  
                  <td style={{borderLeft:'0px',borderRight:'0px'}} name ="rate" >
                  <label htmlFor="rate">Rate Rs. {p.mrp}</label>
                  <input type='hidden' name={`rate[${index}]`} value={p.mrp}></input>
                  </td>
      
                  <td><span style={{ float: 'right' }}>
                    Qty&nbsp;
                    <input
                      type="number"
                      className="form-control"
                      name={`qty[${index}]`}
                      style={{ width: '125px', float: 'right', textAlign: 'right' }}
                      ref={(input) => (qtyInputRefs.current[index] = input)}
                      
                      onChange={(event) => handleQtyChange(index, event)}
                    />
                  </span>
                  <br/><br/><a style={{float: 'right'}}>Unit : {p.uom}</a>
                  <br/><br/><a style={{float: 'right'}}>Amt: {productData[index].amt}<input type="hidden" style={{fontSize : '20px'}} name={`amt[${index}]`} className="form-control" value={productData[index].amt} onChange={handleInputChange} readOnly></input></a></td>
                  
                </tr>
              ))}
              </>

            ) : (
              <>
                {ProductMasterDataToEdit.map((p, index) => (

                <tr style={{marginTop:'50px',borderBottom:'20px', borderWidth:'10px'}} key={p.productid}>
                  <td>
                    {p.productid}
                    <input type='hidden' name={`productid[${index}]`} value={p.productid} onChange={handleInputChange}></input>
                  </td>
                  {/* <td>{p.product_name}</td> */}
                  <td>
                    <label htmlFor="product_name">{p.product_name}</label>
                    <input type='hidden' name={`product_name[${index}]`} value={p.product_name} onChange={handleInputChange}></input>
                    <input type='hidden' name={`product_category[${index}]`} value={p.product_category} onChange={handleInputChange}></input>
                    <br/>
                    {p.product_img && (
                      <img
                        src={p.product_img}
                        alt={`Sample Image for ${p.product_name}`}
                        style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: '10px' }}
                      />
                    )}              
                  </td>
                  
                  <td style={{borderLeft:'0px',borderRight:'0px'}} name ="rate" >
                  <label htmlFor="rate">Rate Rs. {p.mrp}</label>
                  <input type='hidden' name={`rate[${index}]`} value={p.mrp}></input>
                  </td>
      
                  <td><span style={{ float: 'right' }}>
                    Qty&nbsp;
                    <input
                      type="number"
                      className="form-control"
                      name={`qty[${index}]`}
                      value={ProductMasterDataToEdit[index].qty}
                      style={{ width: '125px', float: 'right', textAlign: 'right' }}
                      ref={(input) => (qtyInputRefs.current[index] = input)}
                      onChange={(event) => handleQtyChange(index, event)}
                    />
                  </span>
                  <br/><br/><a style={{float: 'right'}}>Unit : {p.uom}</a>
                  <br/><br/><a style={{float: 'right'}}>Amt: {ProductMasterDataToEdit[index].amt}<input type="hidden" style={{fontSize : '20px'}} name={`amt[${index}]`} className="form-control" value={ProductMasterDataToEdit[index].amt} onChange={handleInputChange} readOnly></input></a></td>
                  
                </tr>
              ))}
              </>
            )}

          </tbody>
        </table>
      </div>
    </div>
    
    <div style={{ marginTop: '15px', float: 'right', clear: 'both' }}>
      <label htmlFor="billamt" value={totalAmount}>Bill Amount: {totalAmount}</label>  
      <input type='hidden' name="billamt" className="form-control" value={Math.abs(Number(totalAmount)).toFixed(2)} readOnly>
      </input>
    </div>

    <div style={{ marginTop: '15px', float: 'right', clear: 'both' }}>
      <label htmlFor="totpay" >Total Amount: {Math.abs((Number(formData.prevbalrs) + Number(totalAmount)).toFixed(2))} </label>  
      <input type='hidden' name="totpay" className="form-control" value={Math.abs((Number(formData.prevbalrs) + Number(totalAmount)).toFixed(2))} readOnly>
      </input>
    </div>

    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', float: 'right', clear: 'both' }}>
      <label htmlFor="cash_recd" style={{ marginRight: '10px', width: '200px' }}>Cash Received:</label>
      <input type='text' className='form-control' style={{ width: '200px', textAlign: 'right' }} value={formData.cash_recd} name="cash_recd" onChange={handleInputChange} onBlur={calculate_balance}/>
    </div>

    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', float: 'right', clear: 'both' }}>
      {/* <label htmlFor="bank_recd" style={{ marginRight: '10px', width: '200px' }}>Bank Received:</label> */}
      <select className='form-select' style={{ marginRight: '10px', width: '200px' }} name='bankname' value={formData.bankname} onChange={handleInputChange}>
      {defaultbank && (
        <option key={defaultbank} value={defaultbank}>
          {defaultbank}
        </option>
      )}

      {BankAccountData.map((option) => (
        defaultbank !== option.bankname ?
          <option key={option.bankname} value={option.bankname} >
            {option.bankname}
          </option>
        : null
      ))}

      {/* {BankAccountData.map((option) => (
        <option key={option.bankname} value={option.bankname} >{option.bankname}</option>
      ))} */}
      </select>
      <input type='text' className='form-control' style={{ width: '200px', textAlign: 'right' }} value={formData.bank_recd} name="bank_recd" onChange={handleInputChange} onBlur={calculate_balance}/>
    </div>

    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', marginBottom: '15px', float: 'right', clear: 'both' }}>
      <label htmlFor="balance" style={{ marginTop: '15px', float: 'right', clear: 'both' }}>Current Balance: {(Number(formData.balance)).toFixed(2)}</label>
      {/* <input type='text' className='form-control' style={{ width: '200px' }} value={formData.balance} name="balance" onChange={handleInputChange} /> */}
      <input type='hidden' name="balance" className="form-control" value={(Number(formData.balance)).toFixed(2)} onChange={handleInputChange} >
        {/* {Math.abs(Number(formData.balance)).toFixed(2)} */}
      </input>
    </div>
    
    <input type='hidden' name="transid" className="form-control" value={formData.transid} onChange={handleInputChange} ></input>
    <input type='hidden' name="operationtype" className="form-control" value={formData.operationtype} onChange={handleInputChange} ></input>

    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', marginBottom: '15px', justifyContent: 'space-between', clear: 'both'}}>
      <button className="btn btn-primary" style={{marginRight: '5px'}} onClick={navigatetodailydelivery}>Back To Daily Order Sheet</button>
      <button type="submit" className="btn btn-primary">{formData.operationtype == 'Save' ? 'Save' : 'Edit'}</button>
    </div>

    </form>
  </div>
  
  );

}

export default SalesMaster;
