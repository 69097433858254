import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Receipt() {

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const usertimestampglobal = JSON.parse(localStorage.getItem('usertimestamp'));

  const navigate = useNavigate();

  const Redirectif = () => {
    useEffect(() => {
      if (usernameglobal == null) {
        navigate('/LoginPage');
      }
    }, [usernameglobal, navigate]);
  }

  try {
    if (usertimestampglobal != null) {
      const parsedTimestamp = parseInt(usertimestampglobal, 10);
      const currentTime = new Date().getTime();
      const twelveHoursAgo = currentTime - (12 * 60 * 60 * 1000); // 12 hours in milliseconds

      if (parsedTimestamp < twelveHoursAgo) {
        localStorage.removeItem('usertimestamp');
        localStorage.removeItem('username');
        Redirectif();
      }
    }
  }
  catch {
    
  }

  if(usernameglobal == null){
    Redirectif();
  }

  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);

  useEffect(() => {
    // Fetch data from the specified URL
    axios.get('https://reactfadke.crmwindow.com/api/public/UserMaster/getdatabyusername?username='+usernameglobal)

        .then((response) => {
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        })
        .catch((error) => console.error('Error fetching data:', error));
  }, [usernameglobal]);

  //Below code is for making these page only accessible  to authorized users i.e., admin and manager.
  useEffect(() => {
    if(UserMasterDataGlobal.length>0){
        if (
            UserMasterDataGlobal?.[0].usertype !== "ADMIN" &&
            UserMasterDataGlobal?.[0].usertype !== "DAIRY OWNER"
        ) {
            navigate("/");
        }
    }
  }, [UserMasterDataGlobal]);

  const MySwal = withReactContent(Swal)
  const [defaultcenter, setdefaultcenter] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [customer_name_data,  setcustomer_name_data] =  useState([]);
  const [errors,  seterrors] =  useState({});
  const customer_centerwiseidRef = useRef(null);
  const qtyInputRefs = useRef([]);

  const [CenterMasterData, setCenterMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(`https://reactfadke.crmwindow.com/api/public/CenterMaster?CompanyId=${UserMasterDataGlobal[0]?.CompanyId}`)

      .then((response) => {
        // Access the response data directly using response.data
        setCenterMasterData(response.data.CenterMasterData);
        setdefaultcenter(response.data.CenterMasterData[0].centerid);      
        setFormData({
          ...formData,
          centerid: response.data.CenterMasterData[0].centerid,
          centerno: response.data.CenterMasterData[0].centerid,
        })
        getmaxreceiptno("CASH");
        getroutemasterdatabycenterid(response.data.CenterMasterData[0].centerid, "centername");
        
        // if(UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" && UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER") {
        //   setdefaultcenter(UserMasterDataGlobal?.[0]?.centerid);
        //   setFormData({
        //     ...formData,
        //     centerid: UserMasterDataGlobal?.[0]?.centerid,
        //   })
        // }
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const getCenterNameById = (centerid) => {
    const center = CenterMasterData.find(center => center.centerid === centerid);
    return center ? center.centername : '';
  };

  const [formData, setFormData] = useState({
    centerid: '',
    centerno: '',
    routename: '',
    customer_mp_id: '',
    customer_centerwiseid: '',
    customer_name: '',
    customer_place: '',
    customer_mobile: '',
    prevbalrs: '',
    tran_date: '',
    cashrecieved: '',
    bankrecieved: '',
    payment_by: '',
    sub_voucher_type: '',
    amount: '',
    cust_bank_name: '',
    cust_branch_Name: '',
    cust_check_no: '',
    cust_check_date: '',
    operationtype: 'Save',
    receiptno: '',
    clbal: '',
  });
  
  useEffect(() => {
    // Update the date in the formData when the component mounts
    setFormData((prevData) => ({
      ...prevData,
      tran_date : getCurrentDate(),
    }));
  }, []); 

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    if(name == "centerid") {
      const [centerid, centername] = value.split('-');
      updatedFormData.centerno = centerid;
      setdefaultcenter(centerid);
      // getroutemasterdata(centerid, 'd6');
      getroutemasterdatabycenterid(centerid, "centername");
    }

    if(name == "routename") {
      const [routecode, routename] = value.split('-');
      // updatedFormData.routecode = routecode;
      setdefaultroutename(routecode);
      fetchcustomer_name_data(formData.centerid, routecode);
    }

    if(name == "amount") {
      var clbal = Number(formData.prevbalrs) - Number(value);
      clbal = Number(clbal).toFixed(2);
      updatedFormData.clbal = clbal;
    }

    // if(name == "customer_name") {
    //   const [customer_centerwiseid_edit, customer_name_edit] = value.split('-');
    //   //  updatedFormData.customer_centerwiseid_edit = customer_centerwiseid_edit;
    //    getrecordsbycustomer_centerwiseid_edit(customer_centerwiseid_edit, "setcustomer_centerwiseid_edit");
    //    return;
    // }

    if (name === 'sub_voucher_type') {
      // If sub_voucher_type is JANATA BANK or OTHER BANK, show the b part code
      if (value == 'JANATA BANK' || value == 'OTHER BANK') {
        setShowBankSection(true);
      } else {
        setShowBankSection(false);
      }
    }

    if(name == "payment_by") {
      getmaxreceiptno(value);
    }

    setFormData(updatedFormData);
    
  };

  const [ShowBankSection, setShowBankSection] = useState(false); // State to manage visibility of b part code
  const [productData, setProductData] = useState([]);
  const [CustomerTransactionDetailsData, setCustomerTransactionDetailsData] = useState([]);
  //2) using axios

  useEffect(() => {
  // Fetch data from the specified URL
    if (UserMasterDataGlobal.length > 0) {
      axios.get(`https://reactfadke.crmwindow.com/api/public/OtherController/getsalestransactiondetails?orderby=ASC&CompanyId=`+UserMasterDataGlobal?.[0]?.CompanyId)
      .then((response) => {
        // Access the response data directly using response.data
        setCustomerTransactionDetailsData(response.data.CustomerTransactionDetailsData);    
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal]);

  const [defaultroutename, setdefaultroutename] = useState();
  const [RouteMasterData, setRouteMasterData] = useState([]);
  //2) using axios
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0 && defaultcenter) {
      const axiostoapi = `https://reactfadke.crmwindow.com/api/public/RouteMaster/GetRouteByCenterIdAndCompanyId/${UserMasterDataGlobal[0]?.CompanyId}/${defaultcenter}`;
      axios.get(axiostoapi)
        .then((response) => {
          setRouteMasterData(response.data.RouteMasterData);
        })
        .catch((error) => console.error('Error fetching route data:', error));
    }
  //we put defaultcenter below because when its value changes everytime this routemaster useeffect code will get executed
  }, [UserMasterDataGlobal, defaultcenter]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    var cash_recd;
    var bank_recd;

    if(formData.get('payment_by') ==  "CASH"){
      cash_recd = formData.get('amount');
      bank_recd = 0;
    }
    else{
      cash_recd = 0;
      bank_recd = formData.get('amount');
    }
    
    var transaction_id;
    const data = {
      CompanyId: UserMasterDataGlobal?.[0]?.CompanyId,
      centerid: formData.get('centerno'),
      userid: UserMasterDataGlobal?.[0]?.userid,
      trantype: 'RECEIPT',
      tran_date : formData.get('tran_date'),
      payment_by : formData.get('payment_by'),
      sub_voucher_type : formData.get('sub_voucher_type'), 
      routename : defaultroutename,  
      customer_name : formData.get('customer_name'),
      customer_centerwiseid : formData.get('customer_centerwiseid'),
      customer_mp_id : formData.get('customer_mp_id'),
      customer_mobile : formData.get('customer_mobile'),
      customer_place : formData.get('customer_place'),
      cust_bank_name : formData.get('cust_bank_name'),
      cust_branch_name : formData.get('cust_branch_name'),
      cust_check_no : formData.get('cust_check_no'),
      cust_check_date : formData.get('cust_check_date'),
      cramt : formData.get('amount'),
      cash_recd : cash_recd,
      bank_recd : bank_recd,
      balance : formData.get('clbal'),
      operationtype : formData.get('operationtype'),
      transaction_id : formData.get('transaction_id'),
      receiptno : formData.get('receiptno'),
    };

    if(data.operationtype == "Save") {
      const response = await axios.post('https://reactfadke.crmwindow.com/api/public/ReceiptMaster/insert', data);

      if (response.status === 200) {
        if(!isNaN(+response.data.transaction_id)){
          transaction_id = response.data.transaction_id;
          MySwal.fire({
            html: <b>Record Saved Sucessfully!</b>,
            icon: 'success'
          })
          
          const newDataItem = {
            ...data,
            transaction_id: response.data.transaction_id,
            // receiptno: response.data.receiptno,
          };

          setReceiptMasterData((prevData) => {
            return [newDataItem, ...prevData];
          });
          emptyinputs();
        }
      }
  
    } else {

      const response = await axios.post('https://reactfadke.crmwindow.com/api/public/ReceiptMaster/update', data);

      if (response.status === 200) {

          if(response.data.message=="Sucesss"){
              MySwal.fire({
                //   title: <strong>Record Saved Sucessfully!</strong>,
                html: <b>Record updated Sucessfully!</b>,
                icon: 'success'
              })

              setReceiptMasterData((prevData) => {
                const updatedData = [...prevData];
                const index = updatedData.findIndex((record) => record.transaction_id == data.transaction_id);

                if (index !== -1) {
                  updatedData[index] = { ...updatedData[index], ...data };
                }
                return updatedData;
              });
              emptyinputs();
          }
          else if (response.data==="failed"){
            MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
              html: <b>Update failed or no matching records found!</b>,
              icon: 'error'
            })                
          }    
          
          // console.log(response.data);
      } else {
      }
    }
  } 

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  function navigatetologin() {
    localStorage.removeItem('usertimestamp');
    localStorage.removeItem('username');
    navigate('/loginpage');
  } 

  const centernoonblur = async (e) => {
    e.preventDefault();
    var { name, value } = e.target;

    if(value == ''){
      return;
    }
    setdefaultcenter(value);
    formData.centerid = value;
    // getroutemasterdata(value, 'd1');
    getroutemasterdatabycenterid(value);
    fetchcustomer_name_data(value,defaultroutename); 
  };

  //to get the data from centerid/centerno we use this function
  async function getroutemasterdatabycenterid(centerid, rr=null) {
    var data1 = {
        centerid : centerid,
        CompanyId : UserMasterDataGlobal[0]?.CompanyId,
    }
    var response1 = axios.get(`https://reactfadke.crmwindow.com/api/public/RouteMaster/getroutenamebycenterno/${data1.centerid}/${data1.CompanyId}`)
    .then((response1) => {
        setdefaultroutename(response1.data[0].routename);
        setRouteMasterData(response1.data);
        if(rr=="centername") {
            //populating customer_name list using customer_name_data array by calling below function.
            //i put below function here because when page first time renders and we try to change
            //centername from selectbox. it is trying to take routename of previous center.
            fetchcustomer_name_data(centerid,response1.data[0].routename);
        }
    })
  }

  //below function is for putting data in customer_name_data array
  async function  fetchcustomer_name_data(centerid, routecode) {
  
    setcustomer_name_data([]);

    var axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/CustomerMaster/getCustomerDataWithCenterNoAndRouteCode?routecode='+routecode
    +'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId+'&centerid='+centerid;
    
    const response = axios.get(axiosreqtoapi)

    .then((response) => {
        if(response.data.customer_name_data.length > 0) {
            setcustomer_name_data(response.data.customer_name_data);
        }
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
    });
  }

  //This function is for customer_centerwiseid onblur and customer_name selectbox value change
  async function getrecordsbycustomer_centerwiseid_edit(customer_centerwiseid=null, setcustomer_centerwiseid_edit=null, recordcenterid = null, recordroutename = null) {
    
    if(customer_centerwiseid == ''){
      return;
    }
    
    var axiosreqtoapi;
    if(recordcenterid == null) {
      axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/CustomerWiseSalesEntryCard/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
      +'&centerid='+formData.centerno+'&routename='+defaultroutename;
    } else {
      axiosreqtoapi = 'https://reactfadke.crmwindow.com/api/public/CustomerWiseSalesEntryCard/getrecordbybycustomer_centerwiseid?customer_centerwiseid='+customer_centerwiseid+'&CompanyId='+UserMasterDataGlobal?.[0]?.CompanyId
      +'&centerid='+recordcenterid+'&routename='+recordroutename;
    }

    const response = await axios.get(axiosreqtoapi)
    
    .then((response) => {
    
      if(response.data.CustomerMasterData.length > 0){
        //below code is to see if the array we got in response is present already in customer_name_data array.

        const index = customer_name_data.findIndex(item =>
            item.customer_mp_id === response.data.CustomerMasterData[0].customer_mp_id
        );
 
        // If response array already exists in customer_name_data, move it to the first index
        if (index !== -1) {                
            const removedArray = customer_name_data.splice(index, 1)[0];        
            // Then insert it at the beginning
            const newData = [removedArray, ...customer_name_data];
            setcustomer_name_data(newData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
                 //putting the existing array customer_name to the value of select box
                 setFormData((prevData) => ({
                    ...prevData,
                    customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                    customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                    customer_place: response.data.CustomerMasterData[0].customer_place,
                    // prevbalrs: response.data.CustomerMasterData[0].clbal,
                }));
                getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }
            else if(setcustomer_centerwiseid_edit==null) {
                setFormData((prevData) => ({
                  ...prevData,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                    customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                    customer_place: response.data.CustomerMasterData[0].customer_place,
                    // prevbalrs: response.data.CustomerMasterData[0].clbal,
                }));
                getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }               
            else if(setcustomer_centerwiseid_edit=="customer_name_only") {
              setFormData((prevData) => ({
                ...prevData,
                customer_name : response.data.CustomerMasterData[0].customer_name,
              }));
            }

        } else {
            // If it doesn't exist, push it normally
            setcustomer_name_data(response.data.CustomerMasterData);

            if(setcustomer_centerwiseid_edit=="setcustomer_centerwiseid_edit") {
              //putting the existing array customer_name to the value of select box
              setFormData((prevData) => ({
                 ...prevData,
                 customer_centerwiseid: response.data.CustomerMasterData[0].customer_centerwiseid,
                 customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                 customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                 customer_place: response.data.CustomerMasterData[0].customer_place,
                //  prevbalrs: response.data.CustomerMasterData[0].clbal,
              }));
              getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }
            else if(setcustomer_centerwiseid_edit==null) {
                setFormData((prevData) => ({
                  ...prevData,
                    customer_name: response.data.CustomerMasterData[0].customer_name,
                    customer_mp_id: response.data.CustomerMasterData[0].customer_mp_id,
                    customer_mobile: response.data.CustomerMasterData[0].customer_mobile,
                    customer_place: response.data.CustomerMasterData[0].customer_place,
                    // prevbalrs: response.data.CustomerMasterData[0].clbal,
                }));
                getclbaltoeditrecord(response.data.CustomerMasterData[0].customer_mp_id, formData.tran_date);
            }                
            else if(setcustomer_centerwiseid_edit=="customer_name_only") {
              setFormData((prevData) => ({
                ...prevData,
                customer_name : response.data.CustomerMasterData[0].customer_name,
              }));
            }
        }

      } else {
        //If no data from API then clear customer_centerwiseid field and apologies message
        if(formData.customer_centerwiseid !== '') {
            alert("No transactions found for this Customer CenterWise ID...");
            setFormData({
                ...formData,
                customer_centerwiseid: '',
                customer_mp_id: '',
                customer_mobile: '',
                customer_place: '',
                prevbalrs: '',
            });                
        }        
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });    
  }

  const [ReceiptMasterData, setReceiptMasterData] = useState([]);
  useEffect(() => {
    if (UserMasterDataGlobal.length > 0 && formData.tran_date !== '') {
      var payment_by = formData.payment_by;
      if(payment_by == "") {
        payment_by = "CASH";
      }

      axios.get(`https://reactfadke.crmwindow.com/api/public/ReceiptMaster/getdata/${formData.tran_date}/${UserMasterDataGlobal?.[0]?.CompanyId}/${payment_by}`)
          .then((response) => {
            setReceiptMasterData(response.data);
          })
          .catch((error) => console.error('Error fetching data:', error));
    }
  }, [UserMasterDataGlobal, formData.tran_date, formData.payment_by]);

  function confirmDelete(event, id) {
    event.preventDefault();

    MySwal.fire({
        title: 'Are you sure you want to delete the record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            if (result.value) {
            // window.location.href = $(this).attr('href');
            deleteData(id);
            }
        }
    })
}

async function deleteData(id) {

  const response = await axios.delete(`https://reactfadke.crmwindow.com/api/public/ReceiptMaster/delete/${id}/${UserMasterDataGlobal?.[0]?.CompanyId}`);
  
  if (response.status === 200) {
    // The record was deleted successfully.
    // console.log('Record deleted successfully!');
      if(response.data==="Sucessfully deleted") {
          MySwal.fire({
          //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record deleted Sucessfully!</b>,
          icon: 'success'
          });
          setReceiptMasterData((prevData) => prevData.filter((record) => record.transaction_id !== id));  
          
          //after deletion of record we forcefully change paymeny_by option to CASH
          getmaxreceiptno("CASH");
          setFormData((prevData) => ({
            ...prevData,
            payment_by:'CASH',
          }));

        } else if(response.data == "found") {
        MySwal.fire({
        //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>This customer has transactions; so can't delete this account</b>,
            icon: 'warning'
        })        
      } 
} else {
      MySwal.fire({
      //   title: <strong>Record Saved Sucessfully!</strong>,
          html: <b>Record not deleted! Please try once again or call the developer</b>,
          icon: 'error'
      })
  }
}

function getdatabyrecordarray(record) {
  setdefaultroutename(record.routename);
  // console.log(record.routename);
  setFormData({
    ...formData,
    transaction_id : record.transaction_id ,
    tran_date: record.tran_date,
    payment_by: record.payment_by,
    sub_voucher_type: record.sub_voucher_type,
    receiptno: record.receiptno,
    centerid: record.centerid,
    centerno: record.centerid,
    routename: record.routename,
    customer_centerwiseid: record.customer_centerwiseid,
    amount: record.cramt,
    cust_bank_name: record.cust_bank_name,
    cust_branch_name: record.cust_branch_name,
    cust_check_no: record.cust_check_no,
    cust_check_date: record.cust_check_date,
    operationtype: 'Edit',
    customer_mp_id: record.customer_mp_id,  
  });
  getrecordsbycustomer_centerwiseid_edit(record.customer_centerwiseid, 'customer_name_only', record.centerid, record.routename);
  getclbaltoeditrecord(record.customer_mp_id, record.tran_date);
  // following code is to go to top of the page after clicking on edit button.
  window.scrollTo(0, 0)
}

async function getclbaltoeditrecord(customer_mp_id, tran_date){
  try {
    const axiosreqtoapi = `https://reactfadke.crmwindow.com/api/public/ReceiptMaster/GetclbalOfSpecificDate/${customer_mp_id}/${tran_date}`;
    // console.log(axiosreqtoapi);
    const response = await axios.get(axiosreqtoapi);
    var prevclbal = response.data;
    setFormData((prevData) => ({
      ...prevData,
      prevbalrs:prevclbal,
    }));
  } catch (error) {
    console.error('Error fetching data:', error);
    return null; // Return null in case of error
  }
}

  function emptyinputs() {
    setFormData({
      centerid: '',
      centerno: '',
      routename: '',
      customer_mp_id: '',
      customer_centerwiseid: '',
      customer_name: '',
      customer_place: '',
      customer_mobile: '',
      prevbalrs: '',
      tran_date: '',
      cashrecieved: '',
      bankrecieved: '',
      payment_by: '',
      sub_voucher_type: '',
      amount: '',
      clbal: '',
      cust_bank_name: '',
      cust_branch_Name: '',
      cust_check_no: '',
      cust_check_date: '',
      operationtype: 'Save',
    });
    getmaxreceiptno("CASH");
  }


    async function getmaxreceiptno(payment_by = null) {

      const axiosreqtoapi = `https://reactfadke.crmwindow.com/api/public/ReceiptMaster/countmaxreceiptno/${UserMasterDataGlobal?.[0]?.CompanyId}/${payment_by}`;
      
      axios.get(axiosreqtoapi)

        .then((response) => {
          setFormData((prevData) => ({
            ...prevData,
            receiptno:response.data,
          }));
        })
        .catch((error) => console.error('Error fetching data:', error));
    }
  
  const printContent = (data) => {

    const printWindow = window.open('', '_blank');

    const parts = data.tran_date.split('-');
    // Rearrange the parts to form 'DD-MM-YYYY' format
    const tran_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
    var cust_check_date;
    if(data.cust_check_date !== null) {
      const parts = data.cust_check_date.split('-');
      // Rearrange the parts to form 'DD-MM-YYYY' format
      cust_check_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    else{
      cust_check_date = null;
    }

    printWindow.document.write(`
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
      <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Receipt</h1>
      <div class="table-container col-md-12 mt-3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="color: red;">Payment by</th>
              <td>${data.payment_by}</td>
              <th style="color: red;">Payment type</th>
              <td>${data.sub_voucher_type}</td>
            </tr>
            <tr>
              <th style="color: red;">Receipt Date</th>
              <td>${tran_date}</td>
              <th style="color: red;">Center Name</th>
              <td>${getCenterNameById(data.centerid)}</td>
            </tr>
            <tr>
              <th style="color: red;">Line No</th>
              <td>${data.routename}</td>
              <th style="color: red;">Customer Name</th>
              <td>${data.customer_name}</td>
            </tr>
            <tr>
              <th style="color: red;">Receipt No</th>
              <td>${data.receiptno}</td>
              <th style="color: red;">Amount</th>
              <td>${data.cramt}</td>
            </tr>
            <tr>
              <th style="color: red;">Bank Name</th>
              <td>${data.cust_bank_name == null ? '' : data.cust_bank_name}</td>
              <th style="color: red;">Branch</th>
              <td>${data.cust_branch_name == null ? '' : data.cust_branch_name}</td>
            </tr>
            <tr>
              <th style="color: red;">Cheque No</th>
              <td>${data.cust_check_no == null ? '' : data.cust_check_no}</td>
              <th style="color: red;">Cheque date</th>
              <td>${cust_check_date == null ? '' : cust_check_date}</td>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  // Define a state variable to keep track of whether customer_centerwiseid input has been blurred
  const [customerCenterwiseIdBlurred, setCustomerCenterwiseIdBlurred] = useState(false);

  // Blur event handler for customer_centerwiseid
  const handleCustomerCenterwiseIdBlur = () => {
    setCustomerCenterwiseIdBlurred(true); // Set the flag to true when blurred
    getrecordsbycustomer_centerwiseid_edit(formData.customer_centerwiseid);
  };

  //below code is used to place customer_centerwiseid of selected customer from datalist in its inputbox.
  // Select event handler for customer_name
  const handleDatalistSelect = (e) => {
    const selectedOption = e.target.value;
    const selectedCustomer = customer_name_data.find((option) => option.customer_name === selectedOption);
    if (selectedCustomer) {
      setFormData((prevData) => ({
        ...prevData,
        customer_centerwiseid: selectedCustomer.customer_centerwiseid,
      }));
      // Check if customer_centerwiseid has been blurred recently before calling the function
      if (!customerCenterwiseIdBlurred) {
        getrecordsbycustomer_centerwiseid_edit(selectedCustomer.customer_centerwiseid, "setcustomer_centerwiseid_edit");
      }
    }
    // Reset the flag after handling the select event
    setCustomerCenterwiseIdBlurred(false);
  };

  return (

    <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
    <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px' }} className="text-center"> Receipt </h1>
      <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color: 'black', backgroundColor: 'lightgreen', marginTop:'-8px'}}>
          <a className="navbar-brand" href="/" >Home</a>
          {/* <a className="navbar-brand" href="/"style={{ marginLeft: "-270px" }}>Back to Order Sheet</a> */}
          <ul className="navbar-nav">
              <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>{`Welcome ${UserMasterDataGlobal?.[0]?.username}`}</span>
              </li>
          </ul>
          <a className="navbar-brand" onClick={navigatetologin} style={{ cursor: 'pointer' }}>Log out</a>
          </div>
      </nav>
    
    <form onSubmit={handleSubmit} method="POST" id="form1">
    <div className="row" styles="background-color:lightgreen;">
      <div className="col-md-10 offset-md-2">
        {/* <form onSubmit={handleSubmit} method="POST" id="form1"> */}
          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-tran_date" className="form-label">Date</label>
              {/* to dispplay record by date we also used tran_date in a useeffect */}
              <input type="date" className="form-control" name="tran_date" value={formData.tran_date}  onChange={handleInputChange} />
            </div>
            <div className="col-md-2">
              <label htmlFor="paymentby" className="form-label">Payment by</label>
              <select className='form-select' name="payment_by" value={formData.payment_by} onChange={handleInputChange}>
                <option value="CASH">CASH</option>
                <option value="BANK">BANK</option>
              </select>
            </div>

            <div className='col-md-2'>
              <label htmlFor="SubVoucherType" className="form-label">Sub voucher type </label>
              <select className='form-select' name="sub_voucher_type" value={formData.sub_voucher_type} onChange={handleInputChange}>
                <option value="CASH">CASH</option>
                <option value="ONLINE CASH">ONLINE CASH</option>
                <option value="JANATA BANK">JANATA BANK</option>
                <option value="OTHER BANK">OTHER BANK</option>
              </select>
            </div>

            <div className='col-md-2'>
              <label htmlFor="receiptno" className="form-label">Voucher No</label>
              {/* max + 1 this formula for below value */}
              <input type="number" className="form-control" name="receiptno" value={formData.receiptno}  onChange={handleInputChange} readOnly/>
            </div>

          </div>
          
          <div className="row mb-3">
            <div className="col-md-2">
                <label htmlFor="id-centerno" className="form-label"> Center No </label>
                <input type="number" className="form-control" name="centerno" placeholder='Type here' 
                value={formData.centerno}  onBlur={centernoonblur} onChange={handleInputChange}
                required 
                disabled={
                  UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                  UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                }/>

                {errors.centerno && <span style={{color: 'red', fontSize: '10px'}}>{errors.centerno}</span>}
            </div> 
            <div className="col-md-2">
              <label htmlFor="centerid" className="form-label">Center</label>
              <select
                  className="form-select"
                  name="centerid" // <-- should match `formData.uom1` property
                  value={formData.centerid}
                  onChange={handleInputChange}
                  disabled={
                    UserMasterDataGlobal?.[0]?.usertype !== "ADMIN" &&
                    UserMasterDataGlobal?.[0]?.usertype !== "DAIRY OWNER"
                  }
                >
                {/* uom option from selected record when available */}
                {defaultcenter && (
                  <option key={defaultcenter} value={defaultcenter}>
                    {getCenterNameById(defaultcenter)}
                  </option>
                )}

                {/* dynamically fetched options */}
                {CenterMasterData.map((cp) => (
                  defaultcenter !== cp.centerid ?
                    <option key={cp.centerid} value={cp.centerid} >
                      {cp.centername}
                    </option>
                  : null
                ))}
                </select>              
            </div>

            <div className="col-md-2">
              <label htmlFor="id-routename" className="form-label">Line Name</label>
              
              <select
              name="routename"
              className="form-select"
              id="id-routename"
              value={formData.routename}
              onChange={handleInputChange} >

              {defaultroutename && (
                <option value={defaultroutename}>
                  {defaultroutename}
                </option>
              )}

              {RouteMasterData.map((r) => (
                 defaultroutename !== r.routename ?
                 <option key={r.routecode} value={r.routename}>
                   {r.routename}
                 </option>
                  : null
              ))}

              </select>

            </div>
          
          </div>

          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="id-purchase_rate" className="form-label">Customer No</label>
              <input type="number" className="form-control" name="customer_centerwiseid" ref={customer_centerwiseidRef} value={formData.customer_centerwiseid} onChange={handleInputChange} onBlur={() => handleCustomerCenterwiseIdBlur()} placeholder='Type here' required />
              {errors.customer_centerwiseid && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_centerwiseid}</span>}
              <input type="hidden" className="form-control" name="customer_mp_id" value={formData.customer_mp_id} onChange={handleInputChange} />              
              <input type="hidden" className="form-control" name="customer_place" value={formData.customer_place} onChange={handleInputChange} />              
              <input type="hidden" className="form-control" name="customer_mobile" value={formData.customer_mobile} onChange={handleInputChange} />                
              <input type="hidden" className="form-control" name="centerid" value={formData.centerid} onChange={handleInputChange} />      
                            
            </div>
            <div className="col-md-4">
              <label htmlFor="customer_name" className="form-label">Customer Name</label>

              <input
                type='text'
                name='customer_name'
                list='customer_name_list'
                className='form-control'
                value={formData.customer_name}
                onChange={handleInputChange}
                onSelect={handleDatalistSelect}
                autoComplete='off' //to not show cache data in inputbox
              />
              <datalist id="customer_name_list" >
                {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                  <option key={option.customer_centerwiseid} value={option.customer_name} />
                ))}
              </datalist>
            </div>     
          </div>
          <div className='row mb-3'>
            <div className='col-md-2'>
              <label htmlFor="prevbalrs" className="form-label">Prev balance</label>
              <input type="number" className="form-control" name="prevbalrs" value={Number(formData.prevbalrs).toFixed(2)} onChange={handleInputChange} placeholder='Type here' required readOnly/>
            </div>
            <div className='col-md-2'>
              <label htmlFor="amount" className="form-label">Amount</label>
              <input type="number" className="form-control" name="amount" value={formData.amount} onChange={handleInputChange} placeholder='Type here' required />
            </div>
            <div className='col-md-2' style={{display: 'none'}}>
              <label htmlFor="clbal" className="form-label">Closing balance</label>
              <input type="number" className="form-control" name="clbal" value={formData.clbal} onChange={handleInputChange} placeholder='Type here' required readOnly/>
            </div>
          </div>

          {ShowBankSection && ( // Render BankSection code only if ShowBankSection is true
            <>
            <div className='row mb-3'>
              <div className='col-md-4'>

                <label htmlFor="cust_bank_name" className="form-label">Bank Name</label>
                <select
                name="cust_bank_name"
                className="form-select"
                value={formData.cust_bank_name}
                onChange={handleInputChange} 
                >
                <option>SBI</option>
                <option>Union Bank</option>
                <option>HDFC Bank</option>
                </select>
              </div>
              <div className='col-md-4'>
                <label htmlFor="cust_branch_name" className="form-label">Branch</label>
                <input type="text" className="form-control" name="cust_branch_name" value={formData.cust_branch_name} onChange={handleInputChange} placeholder='Type here' required />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-2'>
                <label htmlFor="cust_check_no" className="form-label">Cheque No</label>
                <input type="text" className="form-control" name="cust_check_no" value={formData.cust_check_no} onChange={handleInputChange} placeholder='Type here' required />
              </div>
              <div className='col-md-3'>
                <label htmlFor="cust_check_date" className="form-label">Cheque Date</label>
                <input type="date" className="form-control" name="cust_check_date" value={formData.cust_check_date} onChange={handleInputChange} placeholder='Type here' required />
              </div>
            </div>
            </>
          )}
          
          <input type="hidden" className="form-control" name="transaction_id" value={formData.transaction_id} onChange={handleInputChange} />
          <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype}  onChange={handleInputChange} />

          <div className='row mb-3'>
            <div className='col-md-3'>
              <button type="submit" className="btn btn-primary w-50" >{formData.operationtype == 'Save' ? 'Save' : 'Edit'}</button>
            </div>
          </div>
      </div>
    </div>
    </form>

    <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '300px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '6%'}}>Voucher No</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '6%'}}>Customer Name</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '6%'}}>Payment by</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '6%'}}>Sub Voucher type</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '6%', textAlign: 'right'}}>Amount</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%', textAlign: 'center'}}>Action</th>
                </tr>
              </thead>
              <tbody>
              {ReceiptMasterData.map((c, index) => {
                return (
                  <tr key={c.transaction_id}>
                    <td>{c.receiptno}</td>
                    <td>{c.customer_name}</td>
                    <td>{c.payment_by}</td>
                    <td>{c.sub_voucher_type}</td>  
                    <td style={{textAlign: 'right'}}>{c.cramt}</td>      
                    <td style={{textAlign: 'center'}}>
                      <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-sm">Edit</button>
                      &nbsp;<button onClick={() => printContent(c)} className="btn btn-info btn-sm" style={{backgroundColor: 'green'}}>Print</button>
                      &nbsp;<button onClick={(event) => confirmDelete(event, c.transaction_id)} className="btn btn-danger btn-sm ml-1">
                      Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>      

        </div>
      </div>



  </div>
  
  );


}

export default Receipt;
